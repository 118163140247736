import React,{useState,useEffect,useRef} from 'react'
import {Typography, CardHeader, Card, Avatar, IconButton, Button,
        VideocamSharp as VideocamSharpIcon, ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon, blue} from '../utils/AllImportsHelper';
import { Navigation, Pagination,  A11y } from 'swiper';
// import introVideo from '../assets/video/companyVideo.mp4';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './Videos.css'
import Chip from '@mui/material/Chip';
import ApiService from '../utils/ApiService';

const Tags = () => {
    const swiperRef = useRef(null);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [chips, setChips] = useState([]);

  useEffect(() => {
    const swiper = swiperRef.current.swiper;

    swiper.on('slideChange', () => {
      setIsPrevDisabled(swiper.isBeginning);
      setIsNextDisabled(swiper.isEnd);
    });

    ApiService.get('tagList').then(tagList => {
      setChips(tagList.data.tags)
      // console.log({tagList})
    })

  }, []);

  const handlePrevClick = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const handleNextClick = () => {
    swiperRef.current.swiper.slideNext();
  };

  const renderChips = () => {
    return chips.map((chip, index) => (
      // <Chip key={index} label={chip} sx={{ marginRight: '8px' }} />
      <Button variant="contained" key={index} sx={{ marginRight: '8px',background: 'rgba(0, 0, 0, 0.08)',color:'rgba(0, 0, 0, 0.87)',mt:2,":hover": { color:'#fff' } }}>{chip}</Button>
    ));
  };

  return (
    <div>
       
        <Swiper
        style={{ width: "100%" ,padding:'5px 10px'}}
        // sx={{'& .swiper-wrapper': {width: '100%'}}}
        width="100%"
        ref={swiperRef}
          // install Swiper modules
          modules={[Navigation, A11y]}
          
          breakpoints = {{
            0 : {
              slidesPerView: 1.5,
              spaceBetween:5
            },
            321 : {
              slidesPerView: 2.2,
              spaceBetween:5
            },
          599 : {
            slidesPerView: 3.5,
            spaceBetween:10
          },
          899 : {
            slidesPerView: 4.5,
            spaceBetween:10
          },
          1199 : {
            slidesPerView: 5.5,
            spaceBetween:5
          },
          1399 : {
            slidesPerView: 5.7,
            spaceBetween:5
          },
          1599 : {
            slidesPerView: 7.7,
            spaceBetween:5
          },
          2100 : {
            slidesPerView: 9.5,
            spaceBetween:5
          }
          }}
          navigation
          pagination={{ clickable: true }}
          onSlideChange={() => console.log('slide change')}
        >

          {/* {console.log({data})} */}
          
                <SwiperSlide sx={{width: '100%'}}>
                        {renderChips()}
                </SwiperSlide>
            
          
        </Swiper>

    </div>
  )
}

export default Tags