import React, { useState } from 'react';
import { Image, Line, Group } from 'react-konva';

const BrushEraseTool = ({ node,height, width, stageRef, updateParent, currentNode, mode = 'brush' }) => {

    const [isPaint, setIsPaint] = useState(false);
    const [lines, setLines] = useState(node.lines ?? []);
    const [currentLine, setCurrentLine] = useState([]);
    const [globalCompositeOperation, setGlobalCompositeOperation] = useState("source-over");
  
    const imageRef = React.useRef(null);

    const handleMouseDown = () => {
        if(node.id == currentNode){
            setIsPaint(true);
            setCurrentLine([stageRef.current.getPointerPosition().x,stageRef.current.getPointerPosition().y]);
        }
    }

    const handleMouseUp = () => {
        setIsPaint(false);
        let linesAll = [...lines];
        if(currentLine.length)
            linesAll = [...linesAll,{globalCompositeOperation,line: currentLine}];
        setLines(linesAll);
        updateParent({ ...node, lines: linesAll }, node.id);
        setCurrentLine([]);
    }

    const handleMouseMove = () => {
        if (!isPaint) {
            return;
        }

        setGlobalCompositeOperation(mode === "brush" ? "source-over" : "destination-out");
        setCurrentLine([...currentLine,stageRef.current.getPointerPosition().x,stageRef.current.getPointerPosition().y]);
    };

    return (
        <Group>
            <Image
                x={0}
                y={0}
                width={width}
                height={height}
                ref={imageRef}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
            />
            {lines.map((line,index) => {
                return (
                    <Line
                            key={index}
                            strokeWidth={3}
                            stroke={'black'}
                            points={line.line}
                            globalCompositeOperation={line.globalCompositeOperation}
                        />
                )
            })}
            <Line
                            strokeWidth={3}
                            stroke={'black'}
                            points={currentLine}
                            globalCompositeOperation={globalCompositeOperation}
                        />
        </Group>
    );
};

export default BrushEraseTool;