import axios from 'axios';

// const BASE_URL = 'http://localhost:8000/api/';
// const BASE_URL = 'https://canvasbedev.techeela.org/api/';
const BASE_URL = 'https://canvasdev.eela.tech/api/';

class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {
        categoryList: '/categoryList',
        viewTemplateListByCategory: '/viewTemplateListByCategory',
        getCanvasUser: '/getCanvasUser/2',
        createTemplate:'/createTemplate',
        viewTemplate:'/viewTemplate',
        updateTemplate:'/updateTemplate',
        tagList: '/tagsList'
    //   employees: '/employees'
    };
  }

  async get(endpointKey, params = null, headers = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if(searchParam){
        endpoint = endpoint + '/' + searchParam;
      }
      const response = await this.api.get(endpoint, {
        params,
        headers: headers ? headers : {}
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async post(endpointKey, data = null, headers = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if(searchParam){
        endpoint = endpoint + '/' + searchParam;
      }
      const response = await this.api.post(endpoint, data, {
        headers: headers ? headers : {}
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', {error});
    }
  }

  // async getTemplateById(id) {
  //   try {
  //     const endpoint = this.endpoints.viewTemplate;
  //     const response = await this.api.get(`${endpoint}/${id}`);
  //     return response;
  //   } catch (error) {
  //     throw new Error('API request failed:', error);
  //   }
  // }


}

export default new APIService(BASE_URL);
