import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Stage, Layer, Image, Transformer, Text, Rect, Circle, Star, Line, Group, Shape } from 'react-konva';
import PenTool from './PenTool';
import BrushEraseTool from './BrushEraseTool'

const Canvas = ({ nodes, height, width, currentNode, canvasColor, updateParent, scale, stageRef, currentTool }) => {
    // const [selectedId, selectShape] = React.useState(currentNode);
    // const [dottedLine, setDottedLine] = React.useState([0, 0]);

    // const updateDottedLines = (e, c) => {
    //     console.log({ e, c })
    //     if (c == 'stop') {
    //         setDottedLine([0, 0]);
    //         e.evt.stopImmediatePropagation();
    //         return;
    //     }
    //     let currentShape = nodes.filter(v  => v.id == currentNode)[0]?.end;
    //     if(currentShape)
    //         setDottedLine([currentShape.x, currentShape.x, e.evt.x - 273, e.evt.y - 158]);
    // }

    return (
        <Box id='container'
            sx={{
                width: width,
                height: height,
                overflow: 'auto',
                border: '1px solid'
            }}>
            <Stage
                container='container'
                width={width * scale}
                height={height * scale}
                scaleX={scale}
                scaleY={scale}
                ref={stageRef}
            // onMouseMove={updateDottedLines}
            // onMouseDown={(e) => {checkDeselect(e);updateDottedLines(e, 'stop');}}
            // onMouseLeave={(e) => updateDottedLines(e, 'stop')}
            >
                <Layer>
                    {/* <Group> */}
                    {nodes.map((node, index) => {
                        return (<Group key={node.id}
                            x={0}
                            y={0}
                            width={width}
                            height={height}>
                            {/* {console.log(node.tool == 'PEN', node.tool, node)} */}
                            {node.tool == 'PEN' && (<>
                                <PenTool node={node} tool={node.tool} height={height} width={width} canvasColor={canvasColor} updateParent={updateParent} scale={scale} stageRef={stageRef} currentNode={currentNode} currentTool={currentTool} />
                            </>)}
                            {node.tool == 'BRUSH' && (<>
                                <BrushEraseTool node={node} tool={node.tool} height={height} width={width} canvasColor={canvasColor} updateParent={updateParent} scale={scale} stageRef={stageRef} currentNode={currentNode} currentTool={currentTool} />
                            </>)}
                        </Group>)
                    })}
                    {/* <Line
                            dash={[10, 10, 0, 10]}
                            strokeWidth={3}
                            stroke={'black'}
                            lineCap={'round'}
                            opacity={0.3}
                            points={dottedLine}
                        /> */}

                    {/* </Group> */}
                </Layer>
            </Stage>

        </Box>
    );
};

export default Canvas;