import React,{useState,useEffect,useRef} from 'react'
import {Typography, CardHeader, Card, Avatar, IconButton, Button,
        VideocamSharp as VideocamSharpIcon, ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon, blue} from '../utils/AllImportsHelper';
import { Navigation, Pagination,  A11y } from 'swiper';
// import introVideo from '../assets/video/companyVideo.mp4';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './Videos.css'
import { Stack } from '@mui/system';
// import { Link } from "react-router-dom";
// import VideoThunbnailImg from '../assets/images/videoThunbnailImg.png'

// import Grid from '@mui/material/Grid';
// import Coin from '../assets/images/gold-coin.png';
// import Box from '@mui/material/Box';
// import AnimatedCoin from '../assets/images/animatedCoin.gif';


const Videos = ({data,renderFunc, heading}) => {
  console.log(data)
  // const videoData = [
  //   { id: 1, src: introVideo  },
  //   { id: 2, src: introVideo  },
  //   { id: 3, src: introVideo  },
  //   { id: 4, src: introVideo  },
  //   { id: 5, src: introVideo  },
  //   { id: 6, src: introVideo  },
  //   { id: 7, src: introVideo  },
  //   { id: 8, src: introVideo  },
  // ];


  //SWIPER FOR VIDEOS
  const swiperRef = useRef(null);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  useEffect(() => {
    const swiper = swiperRef.current.swiper;

    swiper.on('slideChange', () => {
      setIsPrevDisabled(swiper.isBeginning);
      setIsNextDisabled(swiper.isEnd);
    });
  }, []);

  const handlePrevClick = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const handleNextClick = () => {
    swiperRef.current.swiper.slideNext();
  };

  
  return (
    <>

    {/* /////////////////////////////   VIDEOS  ///////////////////////////// */}


      <Card sx={{ borderRadius: 'var(--border-radius-dashboard)', height:1,background: '#EEF6FF',boxShadow:'none',mr:2,ml:2}}>
            <CardHeader sx={{pr:0,pl:0}}
              // avatar={
              //   <Avatar sx={{ width:'var(--equal-hw1_5)', height:'var(--equal-hw1_5)', bgcolor: 'var(--bgcolor-policy-avatar)' }}>
              //     <VideocamSharpIcon fontSize="small" />
              //   </Avatar>
              // }

              action={
                <Stack direction="row" alignItems="center" mt={1} mr={1} spacing={1}>
                    <Typography variant="body1" pr={1} color='var(--color-blue)'>See All</Typography>
                    <IconButton aria-label="delete" sx={{'&.Mui-disabled':{backgroundColor: '#DBEAFE'},width:'var(--equal-hw1_25)', height:'var(--equal-hw1_25)' ,backgroundColor: 'var(--color-blue)'}} className='swiper-button' disabled={isPrevDisabled} onClick={handlePrevClick}>
                        <ArrowBackIosIcon sx={{width:'var(--equal-hw0_75)', height:'var(--equal-hw0_75)',ml:'0.25rem'}}/>{/*ml is directly assigned for temporary purposes */}
                    </IconButton>
                    <IconButton aria-label="delete" sx={{'&.Mui-disabled':{backgroundColor: '#DBEAFE'},width:'var(--equal-hw1_25)', height:'var(--equal-hw1_25)', backgroundColor: 'var(--color-blue)' }} className='swiper-button' disabled={isNextDisabled} onClick={handleNextClick}>
                        <ArrowForwardIosIcon sx={{width:'var(--equal-hw0_75)', height:'var(--equal-hw0_75)' }} />
                    </IconButton>
                </Stack>
              }
              title={<Typography variant="h7" pl={.5}>{heading} ({data.length})</Typography>}
    />
        <Swiper
        style={{ width: "100%" ,textAlign:'-webkit-center'}}
        ref={swiperRef}
          // install Swiper modules
          modules={[Navigation, A11y]}
          
          breakpoints = {{
            0 : {
              slidesPerView: 1.5,
              spaceBetween:5
            },
            321 : {
              slidesPerView: 2.2,
              spaceBetween:5
            },
          599 : {
            slidesPerView: 3.5,
            spaceBetween:10
          },
          899 : {
            slidesPerView: 4.5,
            spaceBetween:10
          },
          1199 : {
            slidesPerView: 5.5,
            spaceBetween:5
          },
          1399 : {
            slidesPerView: 5.7,
            spaceBetween:5
          },
          1599 : {
            slidesPerView: 7.7,
            spaceBetween:5
          },
          2100 : {
            slidesPerView: 9.5,
            spaceBetween:5
          }
          }}
          navigation
          pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          // onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >

          {/* {console.log({data})} */}
          {
            data.map((value, index) => {
              return (
                <SwiperSlide key={index} >
                        {/* <video controls
                          poster={VideoThunbnailImg}
                          className="videos-dashboard">
                          <source src={path.src} type="video/mp4"></source>
                        </video> */}
                        {renderFunc(value,index)}
                </SwiperSlide>
            )})
          }
        </Swiper>
      </Card>





{/*  */}
    </>
  )
}

export default Videos

