import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './LayerCreation.css';
import Canvas from '../../component/canvas/Canvas'
import PropTypes from 'prop-types';
import b1 from '../../assets/images/b1.png';
import NavigationIcon from '@mui/icons-material/Navigation';
import CurtainsIcon from '@mui/icons-material/Curtains';
import {
  Box, Button, TextField, Tab, Tabs, Typography, styled, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
  AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
  AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
  Image as ImageIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
  Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, Star as StarIcon, Logout, Settings,
} from '../../utils/AllImportsHelper';

const drawerWidth = 382;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function LayerCreation() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [nodes, setNodes] = React.useState([]);

  // {start: {x: 20, y: 30},control: {x: 30, y: 50},end: {x: 40, y: 50},id: 'something'}
  const [currentNode, setCurrentNode] = React.useState(null);
  const [canvasHeight, setCanvasHeight] = React.useState(550);
  const [canvasWidth, setCanvasWidth] = React.useState(700);
  const [canvasColor, setCanvasColor] = React.useState('#fff');
  const [value, setValue] = React.useState(1);
  const stageRef = React.useRef();
  const [step, setStep] = React.useState([]);
  const [stepFuture, setStepFuture] = React.useState([]);
  const [showField, setShowFields] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [zoomFactor, setZoomFactor] = React.useState(100);
  const [currentTool,setCurrentTool] = React.useState('PEN');

  useEffect(() => {
    let node = {id: getUniqueId(), tool: currentTool};
    // if(currentTool == 'PEN'){
    //   node = {...node, points: [], controlPoints: []}
    // }
    if(nodes.length){
      let nodeLast = nodes[nodes.length - 1];
      if(Object.keys(nodeLast).length == 2){
        nodes.pop();
      }
      console.log("Ki samjhaiye",{nodes})
    }
    setNodes([...nodes,node])
    setCurrentNode(node.id);
  }, [currentTool]);

  const handleCurrentTool = (tool) => {
    setCurrentTool(tool);
  };

  useEffect(() => {
    setShowFields(true);
  }, [showField]);

  const onZoomIn = () => {
    setZoomFactor(parseFloat(zoomFactor) + 1);
  };

  const onZoomOut = () => {
    if (zoomFactor > 0)
      setZoomFactor(parseFloat(zoomFactor) - 1);
  };

  const handleZoomChange = (event) => {
    if (event.target.value > 0)
      setZoomFactor(parseFloat(event.target.value));
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentNode(null)
  };

  const saveTemplate = () => {
    localStorage.setItem('template', JSON.stringify(nodes));
  }

  const getTemplate = () => {
    return localStorage.getItem('template');
  }

  const updateParent = (node, id) => {
    setStepForUndo();
    let updatedNodes = [...nodes];
    let index = nodes.reduce((prev, curr, currIndex) => {
      if (curr.id == id) {
        prev = currIndex;
      }
      return prev;
    }, -1);
    updatedNodes[index] = node;
    console.log("shamen",{nodes})
    setNodes([...updatedNodes]);
  }

  const updateCurrentNode = (currentNode) => {
    setCurrentNode(currentNode);
  }

  const setStepForUndo = () => {
    let prevStep = step;
    prevStep.push(deepCloneObject([...nodes]));
    setStep(prevStep);
  }

  const deepCloneObject = (object) => {
    let obj = object;
    let cloneObject = Object.assign(Array.isArray(obj) ? [] : {}, obj);
    Object.keys(obj).forEach((v) => {
      if (obj[v] && typeof obj[v] === 'object') {
        cloneObject[v] = deepCloneObject(obj[v])
      }
    })
    return cloneObject;
  }

  const undoStep = () => {
    let prevStep = step;
    let currStep = prevStep.pop();
    console.log({ currStep });
    if (currStep) {
      let stepFutureCurrent = deepCloneObject([...stepFuture]);
      stepFutureCurrent.push(deepCloneObject([...nodes]));
      setStepFuture(stepFutureCurrent);
      setStep(prevStep);
      setNodes(currStep);
      setShowFields(false);
    }
  }

  const redoStep = () => {
    let prevStep = stepFuture;
    let currStep = prevStep.pop();
    console.log({ currStep });
    if (currStep) {
      setStepForUndo();
      let stepFutureCurrent = deepCloneObject([...step]);
      stepFutureCurrent.push(deepCloneObject([...nodes]));
      setStepFuture(stepFutureCurrent);
      setStep(prevStep);
      setNodes(currStep);
      setShowFields(false);
    }
  }

  const getCurrentIndex = () => {
    return nodes.reduce((prev, curr, currIndex) => {
      if (curr.id == currentNode) {
        prev = currIndex;
      }
      return prev;
    }, -1);
  }

  const getUniqueId = () => {
    return new Date().getTime();
  }

  const deleteField = () => {
    let index = getCurrentIndex();
    if (index != -1) {
      let nodesCurr = deepCloneObject(nodes);
      nodesCurr.splice(index, 1);
      setNodes([...nodesCurr]);
      setCurrentNode(null);
    }
  }

  const downloadURI = (uri, name) => {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const exportImage = () => {
    const uri = stageRef.current.toDataURL();
    // we also can save uri as file
    // but in the demo on Konva website it will not work
    // because of iframe restrictions
    // but feel free to use it in your apps:
    downloadURI(uri, 'stage.png');
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar sx={{ background: '#EEF6FF', boxShadow: 'none' }}>
          <Toolbar>
            <Link to='/category' className='MuiLink-underlineNone'>
              <IconButton
                color="primary"
                aria-label="Home"
                edge="start"
                sx={{ mr: 2 }}
              >
                <HomeIcon />
              </IconButton>
            </Link>
            <Typography
              variant="h7"
              component="div"
              color={'#000'}
              sx={{ flexGrow: 1 }}
            >
              Happy Birthday Anaisha
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Button variant="outlined" size='medium' startIcon={<PreviewIcon />} sx={{ display: { xs: 'none', sm: 'inline-flex' } }}>PREVIEW</Button>
              <Button variant="outlined" size='medium' startIcon={<DownloadIcon />} sx={{ mx: 1, display: { xs: 'none', sm: 'inline-flex' } }} onClick={exportImage} >DOWNLOAD</Button>
              <Box sx={{ display: { xs: 'flex', sm: 'none' }, alignItems: 'center' }}>
                <PreviewIcon color='primary' />
                <DownloadIcon sx={{ mx: 1 }} color='primary' onClick={exportImage} />
              </Box>
              <Button variant="contained" size='medium' onClick={saveTemplate} >SAVE</Button>
              <IconButton sx={{ p: 0, ml: 3 }}
                onClick={handleClick}
                size="small"
                aria-controls={openMenu ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
              >
                <Avatar alt="Remy Sharp" sx={{ width: 32, height: 32 }} src={b1} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >

                <Typography variant='body1' p={1.5} pl={2}> Welcome Alina, </Typography>
                <Divider />
                <MenuItem onClick={handleClose} sx={{ mt: 1 }}>
                  <ListItemIcon>
                    <CollectionsIcon fontSize="small" />
                  </ListItemIcon>
                  My Templates
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="main" sx={{ p: 0, width: 1, position: 'relative', top: '64px' }}>
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{ flexGrow: 1, bgcolor: '#EEF6FF', display: 'flex', position: 'fixed', top: '64px', width: '100%' }}
            >
              <Tabs
                orientation="vertical"
                value={value}
                initialselectedindex={0}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ background: '#ffffff', borderRadius: open ? '0px' : '12px', '& .MuiTabs-indicator': { display: 'none' } }}
              >
                <Tab label="SELECTION" icon={<NavigationIcon />} {...a11yProps(0)} onClick={() => handleCurrentTool("SELLECTION")} />
                <Tab label="PEN" icon={<CurtainsIcon />} {...a11yProps(1)} onClick={() => handleCurrentTool("PEN")} />
                <Tab label="BRUSH" icon={<CurtainsIcon />} {...a11yProps(2)} onClick={() => handleCurrentTool("BRUSH")} />
              </Tabs>
              <Box sx={{ display: 'flex' }} className='width-100 min-hight-6'>
                <CssBaseline />

                  <Box className="overflow-auto width-100">
                    <Card className='width-100'>
                      <Box sx={{ background: '#E1EDFC', alignItems: 'center', display: 'flex', p: 1, pl: 2, pr: 2, justifyContent: 'space-between' }}>
                        <Box sx={{ cursor: 'pointer' }}><UndoIcon sx={{ mr: 2 }} onClick={undoStep} /><RedoIcon onClick={redoStep} /></Box>
                        <Box sx={{ display: 'flex' }}>
                          <Button variant="outlined" fontSize='large' sx={{ minWidth: '40px', p: '5px 10px', mr: 2 }} onClick={onZoomOut}> <RemoveIcon />
                          </Button>
                          <TextField id="Zoom" label="Zoom" variant="outlined" size="small" sx={{ mr: 2, width: 100 }} value={zoomFactor} onChange={handleZoomChange} InputProps={{ endAdornment: '%', inputProps: { step: 1, type: 'number' } }} />
                          <Button variant="outlined" fontSize='large' sx={{ minWidth: '40px', p: '5px 10px' }} onClick={onZoomIn}
                          > <AddIcon />
                          </Button>
                        </Box>
                      </Box>
                      <Box className='align-center' sx={{ background: '#F9F9F9', p: 5 }}>
                        {
                          showField && (<>
                            <Canvas nodes={nodes} stageRef={stageRef} height={canvasHeight} width={canvasWidth} canvasColor={canvasColor} updateParent={updateParent} updateCurrentNode={updateCurrentNode} currentNode={currentNode} scale={zoomFactor / 100} handleDelete={deleteField}
                              undoStep={undoStep} redoStep={redoStep} currentTool={currentTool} >
                            </Canvas>
                          </>)
                        }
                      </Box>
                    </Card>
                  </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default LayerCreation;
