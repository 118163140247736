import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SketchPicker } from 'react-color';
import { Link } from "react-router-dom";
import './TemplateCreation.css';
import Resize from '../../component/Resize/Resize';
import PropTypes from 'prop-types';
import b1 from '../../assets/images/b1.png';
import WebFont from 'webfontloader';
import PropertyPanel from '../../component/PropertyPanel';
import ApiService from '../../utils/ApiService';
import { createField } from '../../utils/helper/FieldHelper';
import {
  Box, Button, TextField, Tab, Tabs, Typography, styled, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
  AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
  AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
  Image as ImageIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
  Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings,FormatColorFill
} from '../../utils/AllImportsHelper';
import CurtainsIcon from '@mui/icons-material/Curtains';

const drawerWidth = 382;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


function TemplateCreation() {
  const theme = useTheme();
  // const params = useParams();
  // const id = params.id;
  // console.log({id})
  const [open, setOpen] = React.useState(false);
  const [fields, setFields] = React.useState([]);
  const [currentNode, setCurrentNode] = React.useState(null);
  const [canvasHeight, setCanvasHeight] = React.useState(550);
  const [canvasWidth, setCanvasWidth] = React.useState(700);
  const [canvasColor, setCanvasColor] = React.useState('#fff');
  const [value, setValue] = React.useState(0);
  const stageRef = React.useRef();
  const [step, setStep] = React.useState([]);
  const [stepFuture, setStepFuture] = React.useState([]);
  const [showField, setShowFields] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [zoomFactor, setZoomFactor] = React.useState(100);
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const [showColorPicker1, setShowColorPicker1] = React.useState(false);
  const [canvasStrokeWidth, setCanvasStrokeWidth] = React.useState(2);
  const [canvasStrokeColor, setCanvasStrokeColor] = React.useState('#000');
  const [canvasCornerRadius, setCanvasCornerRadius] = React.useState('20');
  const [selectedFont, setSelectedFont] = useState("");
  const [currentTool, setCurrentTool] = useState(null);
  const [viewTemplate, setViewTemplate] = useState([]);
  const [updateTemplate, setUpdateTemplate] = useState([]);

  useEffect(() => {
    console.log({fields})
  }, [fields]);

  const handleCurrentTool = (tool, type) => {
    setCurrentTool(tool);
    let field = {id: getUniqueId(), tool: tool, type};
    if(fields.length){
      let fieldLast = fields[fields.length - 1];
      if(Object.keys(fieldLast).length == 2){
        fields.pop();
      }
    }
    setFields([...fields,field])
    setCurrentNode(field.id);
  };

  const canvasStrokeWidthChange = (e) => {
    setCanvasStrokeWidth(e.target.value.toString());
  };

  const canvasStrokeColorChange = (color) => {
    setCanvasStrokeColor(color.hex);
  };

  const canvasColorChange = (color) => {
    setCanvasColor(color.hex);
  };

  const canvasRadiusChange = (e) => {
    setCanvasCornerRadius(e.target.value.toString());
  };

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  const toggleColorPicker1 = () => {
    setShowColorPicker1(!showColorPicker1);
  };


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const calcTemplateFields = () => {
    switch (value) {
      case 1: return (<>
        <TextFieldsIcon fontSize='small' />
        <Typography variant="body2" pl={1}>Text</Typography>
      </>);
      case 2: return (<>
        <ImageIcon fontSize='small' />
        <Typography variant="body2" pl={1}>Image</Typography>
      </>);
      case 3: return (<>
        <ChangeHistoryTwoToneIcon fontSize='small' />
        <Typography variant="body2" pl={1}>Shape</Typography>
      </>);
      case 4: return (<>
        <FormatColorFill fontSize='small' />
        <Typography variant="body2" pl={1}>Canvas Color</Typography>
      </>);
      case 0: return (<>
        <ImageIcon fontSize='small' />
        <Typography variant="body2" pl={1}>Template Style</Typography>
      </>);
       
    }
  }

  const [templateFields, setTemplateFields] = React.useState(calcTemplateFields());

  useEffect(() => {
    setTemplateFields(calcTemplateFields());
  }, [value]);

  useEffect(() => {
    if(!showField)
      setShowFields(true);
  }, [showField]);

  const onZoomIn = () => {
    setZoomFactor(parseFloat(zoomFactor) + 1);
  };

  const onZoomOut = () => {
    if (zoomFactor > 0)
      setZoomFactor(parseFloat(zoomFactor) - 1);
  };

  const handleZoomChange = (event) => {
    if (event.target.value > 0)
      setZoomFactor(parseFloat(event.target.value));
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (currentNode != null && getCurrentIndex() != -1) {
      let type = fields[getCurrentIndex()].type;
      if (type == 'textNode' ) {
        setValue(1);
      } else if (type == 'headingTextNode') {
        setValue(1);
      }else if (type == 'imageNode') {
        setValue(2);
      } else if (type == 'canvasColor') {
        setValue(0);
      }
      else if(type == 'penNode'){
        setValue(4);
      }else if(type == 'brushNode'){
        setValue(5);
      }else if(type == 'eraserNode'){
        setValue(6);
      } else {
        setValue(3);
      }
    }
  }, [currentNode]);

  useEffect(() => {
    getTemplate();
  }, [])

  React.useEffect(() => {
    if (selectedFont) {
      WebFont.load({
        google: {
          families: [selectedFont],
        },
        active: () => {
          setShowFields(false);
        },
      });
    }
  }, [selectedFont]);

  React.useEffect(() => {
    // Load the Google Fonts CSS file for the selected font.
    if (selectedFont) {
      const cssUrl = `https://fonts.googleapis.com/css2?family=${selectedFont}&display=swap`;
  
      const link = document.createElement("link");
      link.href = cssUrl;
      link.rel = "stylesheet";
      // link.onload = () => {
      //   setShowFields(false);
      // };
  
      document.head.appendChild(link);
  
      // Clean up the dynamically added link when the selectedFont changes
      return () => {
        document.head.removeChild(link);
      };
    } 
  }, [selectedFont])


  const textChange = (e, i, index,eventType) => {
    let field = fields;
    i = getCurrentIndex();
    if (i == -1)
      return;
    let value = e.target.value;
    let ind = index;
    if (eventType === 'fontSelected') {
      // Perform any actions you want when a font is selected
      setSelectedFont(value);
    }
    if (ind == 'textDecoration1') {
      field[i]['textDecoration'] = (value == 'underlined1' && field[i]['textDecoration'] != 'underline') ? 'underline' : '';
    }
    if (ind == 'fontStyle1') {
      field[i]['fontStyle'] = (value.indexOf('italic1') != -1 && value.indexOf('bold1') != -1) ? 'italic bold' : value.indexOf('italic1') != -1 ? 'italic' : value.indexOf('bold1') != -1 ? 'bold' : '';
    }
    if (index == 'showPicker' && field[i][index] != '') {
      value = '';
    }
    if (index == 'strokeWidth') {
      if (!value) {
        value = 0;
      }
    }
    field[i][index] = value;
    setFields([...field]);
  }

  const handleChange = (event, newValue) => {

    setValue(newValue);
    setCurrentNode(null)
  };

  const saveTemplate = async () => {
    // fieldsimage: stageRef.current.toDataURL(),
    // localStorage.setItem('template', JSON.stringify(fields));
    // localStorage.setItem('canvasColor',JSON.stringify(canvasColor))
    // localStorage.setItem('canvasStrokeWidth',JSON.stringify(canvasStrokeWidth))
    // localStorage.setItem('canvasStrokeColor',JSON.stringify(canvasStrokeColor))
    // localStorage.setItem('canvasCornerRadius',JSON.stringify(canvasCornerRadius))
    // ApiService.get('updateTemplate',{},{},localStorage.getItem("templateId")).then(updateTemplate => {
    //   setUpdateTemplate(updateTemplate)
    //   console.log(updateTemplate)
    // })
    try {
      
  
      console.log({viewTemplate})
      const data = {
        name: viewTemplate.data.templateData.name,
        canvasColor : canvasColor,
        canvasStrokeWidth : canvasStrokeWidth,
        canvasStrokeColor : canvasStrokeColor,
        canvasCornerRadius : canvasCornerRadius,
        image: stageRef.current.toDataURL(),
        fields: fields.map(field => {
          const {type,x,y,rotation,width,height,fill,opacity , ...fieldData} = field;
          return {type,x,y,rotation,width,height,fill,opacity,fieldData}
        })
      };

      console.log({data})
      const response = await ApiService.post('updateTemplate',data,{},localStorage.getItem("templateId"));
     console.log(response)
      // document.getElementById('templateName').value = '';
      
      
      // localStorage.setItem("templateId",id);
      // Clear other form fields if necessary
  
      // Handle any success or navigation logic
    } catch (error) {
      console.error('Error submitting the form:', error);
      // Handle error scenario
    }
  }

  const getTemplate = () => {
  //   // setFields(JSON.parse(localStorage.getItem('template')) ?? []);
  //   // setCanvasColor(JSON.parse(localStorage.getItem('canvasColor')) ?? '#fff');
  //   // setCanvasStrokeWidth(JSON.parse(localStorage.getItem('canvasStrokeWidth')) ?? '2');
  //   // setCanvasStrokeColor(JSON.parse(localStorage.getItem('canvasStrokeColor')) ?? '#000');
  //   // setCanvasCornerRadius(JSON.parse(localStorage.getItem('canvasCornerRadius')) ?? '20');
    ApiService.get('viewTemplate',{},{},localStorage.getItem("templateId")).then(viewTemplate => {
      setViewTemplate(viewTemplate)
      console.log(viewTemplate)
      setCanvasColor(viewTemplate.data.templateData.canvasColor);
      setCanvasStrokeWidth(viewTemplate.data.templateData.canvasStrokeWidth);
      setCanvasStrokeColor(viewTemplate.data.templateData.canvasStrokeColor);
      setCanvasCornerRadius(viewTemplate.data.templateData.canvasCornerRadius);
      setFields(viewTemplate.data.templateData.fields?.map(field => {
        let node = {...field}
        if(field.fieldData){
          node = {...node, ...field.fieldData}
          delete node.fieldData
        }

        return node
      }) ?? [])
    })
    // ApiService.get('categoryList').then(categoryList => {
    //   setCategoryList(categoryList.data.categories)
    //   console.log(categoryList.data.categories)
    // })
  }

  // useEffect(() => {
  //   const getTemplate = async () => {
  //     try {
  //       const response = await ApiService.getTemplateById(id);
  //       setViewTemplate(response.data);
  //     } catch (error) {
  //       console.error('Error fetching template:', error);
  //       // Handle error scenario
  //     }
  //   };

  //   getTemplate();
  // }, [id]);

  const handleImageUpload = (event, i) => {
    // Get the selected file from the input element
    var file = event.target.files[0];

    i = getCurrentIndex();
    if (i == -1)
      return;

    // Use the FileReader API to read the file and convert it to a data URL
    var reader = new FileReader();
    reader.onload = function (event) {
      // Get the data URL from the reader result
      var dataUrl = event.target.result;

      let field = fields;
      field[i]['src'] = dataUrl;

      const img = new Image();

      img.onload = function () {
        const width = img.width;
        const height = img.height;

        field[i]['width'] = width > canvasWidth ? canvasWidth - 10 : width;
        field[i]['height'] = height > canvasHeight ? canvasHeight - 10 : height;

        setFields([...field]);
      }

      img.src = dataUrl;
      // Set the data URL as the source of the preview image
      // var previewImage = document.getElementById('preview-image');
      // previewImage.src = dataUrl;
    };
    reader.readAsDataURL(file);
  }

  const getRotationValue = (angle) => {
    if (angle > 360) {
      return angle % 360;
    }
    if (angle < 0) {
      return 360 + (angle % 360);
    }
    return angle || 0;
  }

  const updateParent = (node, style) => {
    if (!fields[getCurrentIndex()])
      return;
    setStepForUndo();
    let field = fields;
    if (style.width)
      field[getCurrentIndex()]['width'] = style.width;
    if (style.height)
      field[getCurrentIndex()]['height'] = style.height;
    field[getCurrentIndex()]['x'] = style.x;
    field[getCurrentIndex()]['y'] = style.y;
    if (style.rotation)
      field[getCurrentIndex()]['rotation'] = getRotationValue(style.rotation);
    if (node.radius)
      field[getCurrentIndex()]['radius'] = node.radius;
    if (node.outerRadius && style.width) {
      let ratio = field[getCurrentIndex()]['innerRadius'] / field[getCurrentIndex()]['outerRadius'];
      field[getCurrentIndex()]['outerRadius'] = parseInt(style.width) / 3;
      field[getCurrentIndex()]['innerRadius'] = (parseInt(style.width) / 3) * ratio;
    }
    field['updatedAt'] = getUniqueId();
    setFields([...field]);
    // setShowFields(false);
  }

  const updateParentTool = (node, id) => {
    setStepForUndo();
    let updatedFields = [...fields];
    let index = fields.reduce((prev, curr, currIndex) => {
      if (curr.id == id) {
        prev = currIndex;
      }
      return prev;
    }, -1);
    updatedFields[index] = node;
    console.log("shamen",{fields})
    setFields([...updatedFields]);
  }

  const updateCurrentNode = (currentNode) => {
    setCurrentNode(currentNode);
  }

  const setStepForUndo = () => {
    let prevStep = step;
    prevStep.push(deepCloneObject([...fields]));
    setStep(prevStep);
  }

  const deepCloneObject = (object) => {
    let obj = object;
    let cloneObject = Object.assign(Array.isArray(obj) ? [] : {}, obj);
    Object.keys(obj).forEach((v) => {
      if (obj[v] && typeof obj[v] === 'object') {
        cloneObject[v] = deepCloneObject(obj[v])
      }
    })
    return cloneObject;
  }

  const undoStep = () => {
    let prevStep = step;
    let currStep = prevStep.pop();
    console.log({ currStep });
    if (currStep) {
      let stepFutureCurrent = deepCloneObject([...stepFuture]);
      stepFutureCurrent.push(deepCloneObject([...fields]));
      setStepFuture(stepFutureCurrent);
      setStep(prevStep);
      setFields(currStep);
      setShowFields(false);
    }
  }

  const redoStep = () => {
    let prevStep = stepFuture;
    let currStep = prevStep.pop();
    console.log({ currStep });
    if (currStep) {
      setStepForUndo();
      let stepFutureCurrent = deepCloneObject([...step]);
      stepFutureCurrent.push(deepCloneObject([...fields]));
      setStepFuture(stepFutureCurrent);
      setStep(prevStep);
      setFields(currStep);
      setShowFields(false);
    }
  }

  const getCurrentIndex = () => {
    return fields.reduce((prev, curr, currIndex) => {
      if (curr.id == currentNode) {
        prev = currIndex;
      }
      return prev;
    }, -1);
  }

  const getUniqueId = () => {
    return new Date().getTime();
  }

  const deleteField = () => {
    let index = getCurrentIndex();
    if (index != -1) {
      let fieldsCurr = deepCloneObject(fields);
      fieldsCurr.splice(index, 1);
      setFields([...fieldsCurr]);
      setCurrentNode(null);
    }
  }

  const duplicateField = () => {
    let index = getCurrentIndex();
    if (index != -1) {
      let fieldToDuplicate = fields[index];
      fieldToDuplicate.x += 10;
      fieldToDuplicate.y += 10;
      setFields([...fields, { ...fieldToDuplicate, id: getUniqueId() }]);
    }
  }

  const bringToFront = () => {
    let index = getCurrentIndex();
    if (index != -1) {
      let updatedFields = deepCloneObject(fields);
      let fieldPositionChange = updatedFields[index];
      updatedFields.splice(index, 1);
      updatedFields.push(fieldPositionChange);
      setFields([...updatedFields]);
    }
  }

  const sendToBack = () => {
    let index = getCurrentIndex();
    if (index != -1) {
      let updatedFields = deepCloneObject(fields);
      let fieldPositionChange = updatedFields[index];
      updatedFields.splice(index, 1);
      updatedFields.unshift(fieldPositionChange);
      setFields([...updatedFields]);
    }
  }

  const addField = (type, data = {}) => {
    let id = getUniqueId();
    setStepForUndo();
    let field = createField(id, type, data,{x: canvasWidth, y: canvasHeight});
    if (!field) {
      // Invalid field type
      return;
    }

    setFields([...fields, field]);
    updateCurrentNode(id);
  };


  const downloadURI = (uri, name) => {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const exportImage = () => {
    const uri = stageRef.current.toDataURL();
    // we also can save uri as file
    // but in the demo on Konva website it will not work
    // because of iframe restrictions
    // but feel free to use it in your apps:
    downloadURI(uri, 'stage.png');
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar sx={{ background: '#EEF6FF', boxShadow: 'none' }}>
          <Toolbar>
            <Link to='/category' className='MuiLink-underlineNone'>
              <IconButton
                color="primary"
                aria-label="Home"
                edge="start"
                sx={{ mr: 2 }}
              >
                <HomeIcon />
              </IconButton>
            </Link>
            <Typography
              variant="h7"
              component="div"
              color={'#000'}
              sx={{ flexGrow: 1 }}
            >
              {viewTemplate?.data?.templateData?.name}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Button variant="outlined" size='medium' startIcon={<PreviewIcon />} sx={{ display: { xs: 'none', sm: 'inline-flex' } }}>PREVIEW</Button>
              <Button variant="outlined" size='medium' startIcon={<DownloadIcon />} sx={{ mx: 1, display: { xs: 'none', sm: 'inline-flex' } }} onClick={exportImage} >DOWNLOAD</Button>
              <Box sx={{ display: { xs: 'flex', sm: 'none' }, alignItems: 'center' }}>
                <PreviewIcon color='primary' />
                <DownloadIcon sx={{ mx: 1 }} color='primary' onClick={exportImage} />
              </Box>
              <Button variant="contained" size='medium' onClick={saveTemplate} >SAVE</Button>
              <IconButton sx={{ p: 0, ml: 3 }}
                onClick={handleClick}
                size="small"
                aria-controls={openMenu ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
              >
                <Avatar alt="Remy Sharp" sx={{ width: 32, height: 32 }} src={b1} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >

                <Typography variant='body1' p={1.5} pl={2}> Welcome Alina, </Typography>
                <Divider />
                <MenuItem onClick={handleClose} sx={{ mt: 1 }}>
                  <ListItemIcon>
                    <CollectionsIcon fontSize="small" />
                  </ListItemIcon>
                  My Templates
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    {/* <Logout fontSize="small" /> */}
                    <AccountCircleIcon fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="main" sx={{ p: 0, width: 1, position: 'relative', top: '64px' }}>
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{ flexGrow: 1, bgcolor: '#EEF6FF', display: 'flex', position: 'fixed', top: '64px', width: '100%' }}
            >
              <Tabs
                orientation="vertical"
                value={value}
                initialselectedindex={3}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ background: '#ffffff', borderRadius: open ? '0px' : '12px', '& .MuiTabs-indicator': { display: 'none' } }}
              >
                <Tab label="BG Color" icon={<FormatColorFill />} {...a11yProps(3)} onClick={handleDrawerOpen} />
                <Tab icon={<TextFieldsIcon />} label="TEXT" {...a11yProps(0)} onClick={handleDrawerOpen} />
                <Tab label="IMAGE" icon={<ImageIcon />} {...a11yProps(1)} onClick={handleDrawerOpen} />
                <Tab label="SHAPE" icon={<ChangeHistoryTwoToneIcon />} {...a11yProps(2)} onClick={handleDrawerOpen} />
                <Tab label="PEN" icon={<CurtainsIcon />} {...a11yProps(5)} onClick={() => {handleCurrentTool("PEN",'penNode');addField('penNode');setValue(4);}} />
                <Tab label="BRUSH" icon={<CurtainsIcon />} {...a11yProps(6)} onClick={() => {handleCurrentTool("BRUSH",'brushNode');addField('brushNode');setValue(5);}} />
                <Tab label="Eraser" icon={<CurtainsIcon />} {...a11yProps(7)} onClick={() => {handleCurrentTool("ERASER",'eraserNode');addField('eraserNode');setValue(6);}} />
                <Tab label="SHAPE" icon={<ChangeHistoryTwoToneIcon />} {...a11yProps(4)} onClick={handleDrawerOpen} sx={{ opacity: 0 }} />
              </Tabs>
              <Box sx={{ display: 'flex' }} className='width-100 min-hight-6'>
                <CssBaseline />
                <Avatar onClick={() => { if (value != 4) handleDrawerOpen(); }}
                  sx={{ ml: -2.2, mt: 1, ...(value == 4 && { opacity: 0.4 }), ...(value != 4 && { cursor: 'pointer' }), ...(open && { display: 'none' }) }} >
                  <ChevronRightIcon sx={{ color: '#1976D2' }} fontSize='small' />
                </Avatar>

                <Drawer
                  sx={{
                    width: drawerWidth,

                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                      width: drawerWidth,
                      left: '83px',
                      backgroundColor: '#F8FBFF',
                      borderRight: '0px',
                      borderTopRightRadius: '12px',
                      top: '64px',
                      boxSizing: 'border-box',
                    },
                  }}
                  variant="persistent"
                  anchor="left"
                  open={open}>
                  <DrawerHeader>
                    <Typography pl={1} variant="subtitle1">Property Panel</Typography>
                    <IconButton onClick={handleDrawerClose} sx={{ color: '#1976D2' }}>
                      {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                  </DrawerHeader>
                  <Box sx={{ display: 'flex', color: 'text.disabled', background: '#F0F3F8', alBoxs: 'center', p: 1, borderBottom: '0.8px solid rgba(100, 116, 139, 0.1)' }}>
                    {templateFields}
                  </Box>
                  {getCurrentIndex() != -1 && <PropertyPanel textChange={textChange} canvasColorChange={canvasColorChange} showColorPicker={showColorPicker} handleImageUpload={handleImageUpload} value={[fields[getCurrentIndex()]]} />}

                  <TabPanel value={value} index={1} >
                    <Button onClick={() => addField('textNode')} variant="outlined">ADD NEW TEXT</Button>
                    <Typography variant="h2" sx={{fontFamily:'Cedarville Cursive',fontWeight:400}} onClick={() => addField('textNode', {width: 320, height: 50,fontSize: 44, value: "Happy Birthday", fontFamily: 'Cedarville Cursive', fill: "#FF4842"})} color='error'>Happy Birthday</Typography>
                   
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Button onClick={() => addField('imageNode')} variant="outlined" sx={{ marginBottom: '1rem' }}>Image</Button>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    {currentNode == null && (<>
                      <Paper
                        component="form"
                        sx={{
                          display: 'flex', alignItems: 'center', boxShadow: 'none', background: '#EFF6FF',
                          borderRadius: '48px', mb: 2
                        }}>
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="Search Box">
                          <SearchIcon />
                        </IconButton>
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Search here"
                          inputProps={{ 'aria-label': 'Search here' }}
                        />
                      </Paper>
                    </>)}

                    <RectangleIcon onClick={() => addField('rectangleNode')} color='action' />
                    <CircleIcon onClick={() => addField('circleNode')} color='action' sx={{ mx: 2 }} />
                    <StarIcon onClick={() => addField('starNode')} color='action' />
                    <EggTwoTone onClick={() => addField('ellipseNode')} color='action' sx={{ ml: 2 }} />
                  </TabPanel>
                  <TabPanel value={value} index={0}>
                    {/* <Button onClick={() => addField('imageNode')} variant="outlined" sx={{ marginBottom: '1rem' }}>Change Color</Button>
                    <Button variant="outlined" sx={{ marginBottom: '1rem' }} onClick={toggleColorPicker}>Change Colors</Button> */}
                    {/* <SketchPickerField  label={'Color'} onChange={(e) => canvasColorChange()} component={<Box sx={{ gridColumnStart: 1, gridColumnEnd: 1, width: 35, height: 35, cursor: 'pointer', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.6)', border: '2px solid transparent', borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)', borderImageSlice: 1 }} />} /> */}
                  <Box sx={{pt:1, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: "100%", rowGap: "1.5rem", columnGap: "1rem"}}>
                   <Box sx={{display:'flex'}}>
                    <Box onClick={toggleColorPicker} sx={{  width: 35, height: 35, cursor: 'pointer', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.6)', border: '2px solid transparent', borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)', borderImageSlice: 1 }} />
                    <Box onClick={toggleColorPicker1} sx={{ mx: 3, width: 35, height: 35, cursor: 'pointer', borderRadius: '4px', backgroundColor: '#FBFDFF', border: '2px solid transparent', borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)', borderImageSlice: 1}} />
                   </Box>
                    <Box sx={{display:'flex'}}>
                    {showColorPicker && ( 
                      <Box sx={{display: 'block',
                      position: 'absolute',
                      top: '22%',
                      left: 2,
                      zIndex: 2,}}>
                   <SketchPicker color={canvasColor} onChange={canvasColorChange}  />
                    {/* <SketchPicker color={canvasStrokeColor} onChange={canvasStrokeColorChange}  /> */}
                    </Box>
                    )}
                    {showColorPicker1 && ( 
                      <Box sx={{display: 'block',
                      position: 'absolute',
                      top: '22%',
                      left: '5rem',
                      zIndex: 2,}}>
                   {/* <SketchPicker color={canvasColor} onChange={canvasColorChange}  /> */}
                    <SketchPicker color={canvasStrokeColor} onChange={canvasStrokeColorChange}  />
                    </Box>
                    )}
                    </Box>
                    {/* <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}> */}
        {/* <label htmlFor="strokeWidth">Stroke Width: </label> */}
        <TextField
          type="number"
          id="strokeWidth"
          sx={{gridColumnStart: 2, gridColumnEnd: 1}}
          variant="outlined" label="Stroke Width"
          value={canvasStrokeWidth}
          onChange={canvasStrokeWidthChange}
        />

<TextField
          type="number"
          id="canvasRadius"
          variant="outlined" label="Canvas Corner Radius"
          value={canvasCornerRadius}
          onChange={canvasRadiusChange}
        />
      </Box>

                  </TabPanel>
                </Drawer>

                <Main open={open} sx={{ pt: 0 }} className="overflow-auto">
                  <Box className="overflow-auto">
                    <Card className='width-100'>
                      <Box sx={{ background: '#E1EDFC', alignItems: 'center', display: 'flex', p: 1, pl: 2, pr: 2, justifyContent: 'space-between' }}>
                        <Box sx={{ cursor: 'pointer' }}><UndoIcon sx={{ mr: 2 }} onClick={undoStep} /><RedoIcon onClick={redoStep} /></Box>
                        {currentNode != null && (<><Box sx={{ cursor: 'pointer' }} ><ContentCopyIcon fontSize='small' sx={{ mx: 2 }} onClick={duplicateField} /><DeleteIcon onClick={deleteField} fontSize='small' /></Box></>)}
                        <Box sx={{ display: 'flex' }}>
                          <Button variant="outlined" fontSize='large' sx={{ minWidth: '40px', p: '5px 10px', mr: 2 }} onClick={onZoomOut}> <RemoveIcon />
                          </Button>
                          <TextField id="Zoom" label="Zoom" variant="outlined" size="small" sx={{ mr: 2, width: 100 }} value={zoomFactor} onChange={handleZoomChange} InputProps={{ endAdornment: '%', inputProps: { step: 1, type: 'number' } }} />
                          <Button variant="outlined" fontSize='large' sx={{ minWidth: '40px', p: '5px 10px' }} onClick={onZoomIn}
                          > <AddIcon />
                          </Button>
                        </Box>
                      </Box>
                      <Box className='align-center' sx={{ background: '#F9F9F9', p: 5 }}>
                        {
                          showField && (<>
                            <Resize fields={fields} canvasCornerRadius={canvasCornerRadius} canvasStrokeColor={canvasStrokeColor} canvasStrokeWidth={canvasStrokeWidth} stageRef={stageRef} height={canvasHeight} width={canvasWidth} canvasColor={canvasColor} updateParent={updateParent} updateCurrentNode={updateCurrentNode} currentNode={currentNode} scale={zoomFactor / 100} handleBringToFront={bringToFront} handleSendToBack={sendToBack} handleDelete={deleteField} handleDuplicate={duplicateField} undoStep={undoStep} redoStep={redoStep} updateParentTool={updateParentTool} currentTool={currentTool}>
                            </Resize>
                          </>)
                        }
                      </Box>
                    </Card>
                  </Box>
                </Main>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default TemplateCreation;
