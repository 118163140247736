export const createField = (id, type, data = {}, ...props) => {
    let field = { id: id, x: 30, y: 30, rotation: 0, updatedAt: id };
  
    switch (type) {
      case 'textNode':
        field = { ...field, type: 'textNode',ellipsis:'true',wrap:'none',opacity:.5, fontSize: 14, value: 'Type Here', fontFamily: 'Poppins', fill: "#000000", width: 67, height: 20, align: 'left', fontStyle1: '', textDecoration1: '', showPicker: '', ...data };
        break;
        // case 'headingTextNode':
        // field = { ...field, type: 'headingTextNode', fontSize: 20, value: 'Happy Birthday', fontFamily: 'Brush Script MT, Brush Script Std, cursive', fill: "#FF4842", width: 67, height: 20, align: 'left', fontStyle1: '', textDecoration1: '', showPicker: '', ...data };
        // break;
      case 'imageNode':
        field = { ...field, type: 'imageNode', src: null, width: 40, height: 40, ...data };
        break;
      case 'circleNode':
        field = { ...field, type: 'circleNode', radius: "50",width: 60, height: 60, fill: "#7ed321", ...data };
        break;
      case 'rectangleNode':
        field = { ...field, type: 'rectangleNode', width: 60, height: 60, fill: "#7ed321", shadowBlur: 10, strokeWidth: 1, stroke: "#000000", ...data };
        break;
      case 'starNode':
        field = { ...field, type: 'starNode', fill: "#7ed321", shadowBlur: 10, outerRadius: 80, innerRadius: 40, stroke: '#005500', strokeWidth: 1, numPoints: 5, lineJoin: 'round', shadowOffsetX: 5, shadowOffsetY: 5, shadowColor: 'black', shadowOpacity: 0.5, opacity: 0.8,width: 40, height: 40, ...data };
        break;
        case 'ellipseNode':
        field = { ...field, type: 'ellipseNode', radius: "50",strokeWidth: 1,shadowBlur: 10, stroke: "#000000",width: 60, height: 100, fill: "#7ed321",lineJoin: 'round',shadowOffsetX: 5, shadowOffsetY: 5, shadowColor: 'black', shadowOpacity: 0.5, opacity: 0.8, ...data };
        break;
      default:
        // Invalid field type
        return null;
    }
    console.log({x: field.width, y: field.height})
    field.x = (props[0].x - field.width) / 2;
    field.y = (props[0].y - field.height) / 2;

    console.log({field,props})
    return field;
  };