import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Stage, Layer, Image, Transformer, Text, Rect, Circle, Star, Line, Group, Ellipse } from 'react-konva';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import shortCutHelper from './helper/ShortCutHelper';
import PenTool from '../canvas/PenTool';
import BrushEraseTool from '../canvas/BrushEraseTool';

// custom component that will handle loading image from url
// you may add more logic here to handle "loading" state
// or if loading is failed
// VERY IMPORTANT NOTES:
// at first we will set image state to null
// and then we will set it to native image instance when it is loaded
const ContextMenu = ({ mouseX, mouseY, onClose, onBringToFront, onSendToBack, onDelete, onDuplicate }) => {
  return (
    <Menu
      open={true}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={{ top: mouseY, left: mouseX }}
    >
      <MenuItem onClick={onBringToFront} >
        <ListItemIcon>
          <ArrowUpwardIcon fontSize="small" />
        </ListItemIcon>
        <Typography >Bring to Front</Typography>
      </MenuItem>
      <MenuItem onClick={onSendToBack}>
        <ListItemIcon>
          <ArrowDownwardIcon fontSize="small" />
        </ListItemIcon>
        <Typography >Send to Back</Typography>
      </MenuItem>
      <MenuItem onClick={onDuplicate}>
        <ListItemIcon>
          <ContentCopyIcon fontSize="small" />
        </ListItemIcon>
        <Typography >Duplicate</Typography>
      </MenuItem>
      <MenuItem onClick={onDelete}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <Typography >Delete</Typography>
      </MenuItem>
    </Menu>
  );
};

class URLImage extends React.Component {
  state = {
    image: null,
  };
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }
  componentWillUnmount() {
    if (this.image)
      this.image.removeEventListener('load', this.handleLoad);
  }
  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = this.props.src ? new window.Image() : null;
    if (this.props.src != null) {

      this.image.src = this.props.src;
      this.image.alt = 'Src Here';
      this.image.addEventListener('load', this.handleLoad);
    }
  }
  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image,
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };
  render() {
    return (
      <Image
        {...this.props}
        image={this.state.image}
        ref={this.props.forwardedRef}
      />
    );
  }
}

const Rectangle = ({ shapeProps, isSelected, onSelect, onChange, node, rotation, updateParent, updateParentTool, stageRef, currentNode, currentTool,width,height,canvasColor,scale }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const leaveTransformerForTool = ['penNode','brushNode','eraserNode'];

  React.useEffect(() => {
    if (isSelected && leaveTransformerForTool.indexOf(node.type) == -1) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  React.useEffect(() => {
    if (node.rotation && trRef.current) {
      shapeRef.current.rotation(node.rotation);
      trRef.current.rotation(node.rotation);
      trRef.current.getLayer().batchDraw();
    }
  }, []);

  React.useEffect(() => {
    if (shapeRef.current && trRef.current) {
      shapeRef.current.rotation(rotation);
      trRef.current.rotation(rotation);
      trRef.current.getLayer().batchDraw();
    }
  }, [rotation]);

  const onDragEnd = (e) => {

    let properties = {
      ...shapeProps,
      x: e.target.x(),
      y: e.target.y(),
      rotation: e.target.attrs.rotation,
    }

    if (e.target.attrs.width)
      properties['width'] = e.target.attrs.width;
    if (e.target.attrs.height)
      properties['height'] = e.target.attrs.height;
    onChange(properties);
    updateParent(node, { ...properties })
  }

  const onTransformEnd = (e) => {
    // transformer is changing scale of the node
    // and NOT its width or height
    // but in the store we have only width and height
    // to match the data better we will reset scale on transform end
    const nodeRect = shapeRef.current;
    const scaleX = nodeRect.scaleX();
    const scaleY = nodeRect.scaleY();

    // we will reset it back
    nodeRect.scaleX(1);
    nodeRect.scaleY(1);

    let properties = {
      ...shapeProps,
      x: nodeRect.x(),
      y: nodeRect.y(),
      // set minimal value
      width: Math.max(5, nodeRect.width() * scaleX),
      height: Math.max(nodeRect.height() * scaleY),
      rotation: e.target.attrs.rotation,
    }
    onChange(properties);
    updateParent(node, properties)
  }

  return (
    <React.Fragment>
      {node.type == 'textNode' ?
        <>

          <Text
            onClick={onSelect}
            onTap={onSelect}
            ref={shapeRef}
            {...node}
            {...shapeProps}
            text={node.value}
            draggable
            onDragEnd={onDragEnd}
            onTransformEnd={onTransformEnd}
          />

        </>
        // : node.type == 'headingTextNode' ?
        // <>

        //   <Text
        //     onClick={onSelect}
        //     onTap={onSelect}
        //     ref={shapeRef}
        //     {...node}
        //     {...shapeProps}
        //     text={node.value}
        //     draggable
        //     onDragEnd={onDragEnd}
        //     onTransformEnd={onTransformEnd}
        //   />

        // </>
        : node.type == 'imageNode' ?
          <URLImage
            onClick={onSelect}
            onTap={onSelect}
            forwardedRef={shapeRef}
            {...shapeProps}
            {...node}
            draggable
            onDragEnd={onDragEnd}
            onTransformEnd={onTransformEnd}
          />
          : node.type == 'circleNode' ?
            <Circle onClick={onSelect}
              onTap={onSelect}
              ref={shapeRef}
              {...node}
              {...shapeProps}
              draggable
              onDragEnd={onDragEnd}
              onTransformEnd={onTransformEnd} />
            : node.type == 'rectangleNode' ?
              <Rect
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...node}
                {...shapeProps}
                draggable
                onDragEnd={onDragEnd}
                onTransformEnd={onTransformEnd}
              />

              : node.type == 'ellipseNode' ?
              <Ellipse
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...node}
                {...shapeProps}
                draggable
                onDragEnd={onDragEnd}
                onTransformEnd={onTransformEnd}
              />
              : node.type == 'starNode' ?
              // :
              <Star
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...node}
                {...shapeProps}
                draggable
                onDragEnd={onDragEnd}
                onTransformEnd={onTransformEnd}
              />
              : node.type == 'penNode' ?
              <PenTool node={node} tool={"PEN"} height={height} width={width} canvasColor={canvasColor} updateParent={updateParentTool} scale={scale} stageRef={stageRef} currentNode={currentNode} currentTool={currentTool} />

              : node.type == 'brushNode' ?
              <BrushEraseTool node={node} mode={"brush"} height={height} width={width} canvasColor={canvasColor} updateParent={updateParentTool} scale={scale} stageRef={stageRef} currentNode={currentNode} currentTool={currentTool} />

              : node.type == 'eraserNode' ?
              <BrushEraseTool node={node} mode={"eraser"} height={height} width={width} canvasColor={canvasColor} updateParent={updateParentTool} scale={scale} stageRef={stageRef} currentNode={currentNode} currentTool={currentTool} />
              :
              <></>
        // : 
        //   <Line
        //      {...shapeProps}
        //      {...node}
        //      onClick={onSelect}
        //       onTap={onSelect}
        //       ref={shapeRef}
        //       draggable
        //       onDragEnd={onDragEnd}
        //       onTransformEnd={onTransformEnd}
        //   />

      }
      {isSelected && leaveTransformerForTool.indexOf(node.type) == -1 && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          anchorStrokeWidth={2}
          anchorCornerRadius={10}
          borderDash = {[10, 5]}
          borderStrokeWidth = {2}
          borderStroke='#19BCD2'
          enabledAnchors={node.type != 'starNode' && node.type !== 'circleNode' ? ['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right'] : ['top-left', 'top-right', 'bottom-left', 'bottom-right']}
        />
      )}
    </React.Fragment>
  );
};

const initialRectangles =
{
  id: 'rect1',
};

export default function Resize({ fields, height, width, updateParent, stageRef,canvasCornerRadius, canvasColor, canvasStrokeColor,canvasStrokeWidth, updateCurrentNode, currentNode, scale, handleBringToFront, handleSendToBack, handleDelete, handleDuplicate, redoStep, undoStep, updateParentTool, currentTool }) {
  const [nodes, setNodes] = React.useState(fields);
  const [rectangles, setRectangles] = React.useState([]);
  const [selectedId, selectShape] = React.useState(currentNode);
  const [contextMenuPosition, setContextMenuPosition] = useState({ mouseX: null, mouseY: null });

  const handleContextMenu = (event) => {
    event.preventDefault();
    if (event.type === 'contextmenu' && currentNode != null) {
      setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    }
  };

  const handleCloseContextMenu = () => {
    setContextMenuPosition({ mouseX: null, mouseY: null });
  };
  React.useEffect(() => {
    updateCurrentNode(selectedId)
  }, [selectedId])

  React.useEffect(() => {
    if (currentNode != selectedId) {
      selectShape(currentNode);
    }
  }, [currentNode])

  React.useEffect(() => {
    setNodes(fields);
    console.log({fields});
  }, [fields])

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const getCurrentIndex = () => {
    console.log({selectedId})
    return nodes.reduce((prev, curr, currIndex) => {
      if (curr.id == selectedId) {
        prev = currIndex;
      }
      return prev;
    }, -1);
  }

  const arrowUp = () => {
    // console.log(selectedId != null && getCurrentIndex() != -1, selectedId != null, getCurrentIndex() != -1, nodes[getCurrentIndex()], getCurrentIndex(), nodes)
    // if (selectedId != null && getCurrentIndex() != -1) {
    //   let i = getCurrentIndex();
    //   let node = nodes[i];
    //   updateParent(node, { x: node.x, y: node.y - 10 })
    // }
  }

  const arrowDown = () => {
    // console.log(selectedId != null && getCurrentIndex() != -1, selectedId != null, getCurrentIndex() != -1, nodes[getCurrentIndex()], getCurrentIndex(), nodes)
    // if (selectedId != null && getCurrentIndex() != -1) {
    //   let i = getCurrentIndex();
    //   let node = nodes[i];
    //   updateParent(node, { x: node.x, y: node.y + 10 })
    // }
  }

  const arrowLeft = () => {
    // console.log(selectedId != null && getCurrentIndex() != -1, selectedId != null, getCurrentIndex() != -1, nodes[getCurrentIndex()], getCurrentIndex(), nodes)
    // if (selectedId != null && getCurrentIndex() != -1) {
    //   let i = getCurrentIndex();
    //   let node = nodes[i];
    //   updateParent(node, { x: node.x - 10, y: node.y })
    // }
  }

  const arrowRight = () => {
    // console.log(selectedId != null && getCurrentIndex() != -1, selectedId != null, getCurrentIndex() != -1, nodes[getCurrentIndex()], getCurrentIndex(), nodes)
    // if (selectedId != null && getCurrentIndex() != -1) {
    //   let i = getCurrentIndex();
    //   let node = nodes[i];
    //   updateParent(node, { x: node.x + 10, y: node.y })
    // }
  }

  React.useEffect(() => {
    let keyBoardHelper = shortCutHelper({insertNode: handleDuplicate, deleteNode: handleDelete, arrowUp: arrowUp, arrowDown: arrowDown, arrowLeft: arrowLeft, arrowRight: arrowRight, undoAction: undoStep, redoAction: redoStep});
    keyBoardHelper.startListning();
    return () => {
      keyBoardHelper.stopListning();
    }
  }, []);

  if (rectangles.length != nodes.length) {
    setRectangles(nodes.map(v => { return initialRectangles }))
  }

  return (
    <Box id='container'
      onContextMenu={handleContextMenu}
      sx={{
        width: width,
        height: height,
        overflow: 'auto'
      }}>
      <Stage
        container='container'
        width={width * scale}
        height={height * scale}
        onMouseDown={checkDeselect}
        onTouchStart={checkDeselect}
        ref={stageRef}
        scaleX={scale}
        scaleY={scale}
      >
        <Layer>
          <Rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill={canvasColor}
            strokeWidth={canvasStrokeWidth}
            stroke={canvasStrokeColor}
            listening={false}
            cornerRadius={parseInt(canvasCornerRadius)}
          />
          {console.log('Cahin',{nodes})}
          {nodes.map((rect, i) => {
            return (

              <Rectangle
                key={'' + rect.id + rect.updatedAt}
                shapeProps={rectangles[i]}
                isSelected={rect.id === selectedId}
                node={nodes[i]}
                rotation={nodes[i].rotation}
                updateParent={updateParent}
                stageRef={stageRef}
                currentNode={currentNode}
                updateParentTool={updateParentTool}
                width={width}
                height={height}
                canvasColor={canvasColor}
                scale={scale}
                currentTool={currentTool}
                onSelect={() => {console.log({selectedId});
                  selectShape(rect.id);
                }}
                onChange={(newAttrs) => {
                  const rects = rectangles.slice();
                  rects[i] = newAttrs;
                  setRectangles(rects);
                }}
              />


            );
          })}
        </Layer>
      </Stage>
      {contextMenuPosition.mouseY !== null && (
        <ContextMenu mouseX={contextMenuPosition.mouseX} mouseY={contextMenuPosition.mouseY} onClose={handleCloseContextMenu} onBringToFront={() => { handleBringToFront(); handleCloseContextMenu() }} onSendToBack={() => { handleSendToBack(); handleCloseContextMenu() }} onDelete={() => { handleDelete(); handleCloseContextMenu() }} onDuplicate={() => { handleDuplicate(); handleCloseContextMenu() }} />
      )}
    </Box>
  );
};
