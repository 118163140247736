import React from 'react';
// import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText'
// import { Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core';

const InputSingleSelect = ({label,model,modelKey,validation,type, change = () => {}, value, data, ...props}) => {

  const [selectValue, setSelectValue] = React.useState(value);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);

  const onChangeTextValue = (e) => {
    setSelectValue(e.target.value);
    // model[modelKey].value = e.target.value;

    let error = false;
    let errorMessage = false;
    for( let i = 0; i < validation?.length; i ++){
      let res = validation[i](e.target.value);
      if(res.error){
        error = res.error;
        errorMessage = res.errorMessage;
        break;
      }
    }
    setError(error);
    setErrorMessage(errorMessage);
    change(e);
  }

  return (
    <>
      <FormControl size="small" error={error} sx={{height: 'inherit'}}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          value={selectValue}
          onChange={onChangeTextValue}
          {...props}
          sx={{height: '100%'}}
        >
          {data?.map((v,i) => (<MenuItem key={i} value={v.value}>{v.label}</MenuItem>))}
        </Select>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </>
  )
}

export default InputSingleSelect