import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import './Category.css';
import axios from 'axios';
import Video from '../../component/Videos';
// import b1 from '../../assets/images/b1.png';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import CakeIcon from '@mui/icons-material/Cake';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import b1 from '../../assets/images/b1.png';
import thpLogo from '../../assets/images/thp-logo.png';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import CollectionsIcon from '@mui/icons-material/Collections';

import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import ApiService from '../../utils/ApiService';
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Grid } from '@mui/material';
import Tags from '../../component/Tags';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';

const filter = createFilterOptions();
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: 'Balloons' },
  { title: 'Flower' },
  { title: 'Food' },
  { title: 'Party' },
  { title: 'Nature' },

];



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   marginLeft: `${drawerWidth}px`,
  //   transition: theme.transitions.create(['margin', 'width'], {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),
}));

function Category({ userRole }) {
  // console.log(userRole)
  const [categoryList, setCategoryList] = useState([]);
  const [viewTemplateListByCategory, setViewTemplateListByCategory] = useState([]);
  const [getCanvasUser, setGetCanvasUser] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addTemplateAnchor, setAddTemplateAnchor] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const openAddTemplate = Boolean(addTemplateAnchor);
  const [categoryDialogOpen, setCategoryDialogOpen] = React.useState(false);
  const [category, setCategory] = React.useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const navigate = useNavigate();

  const handleTagsChange = (event, value) => {
    setSelectedTags(value);
  };


  const categoryChange = (event) => {
    setCategory(event.target.value);
  };

  const dialogOpen = () => {
    setCategoryDialogOpen(true);
  };
  const dialogClose = () => {
    setCategoryDialogOpen(false);
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickAddTemp = (event) => {
    setAddTemplateAnchor(event.currentTarget);
  };
  const handleCloseTemp = () => {
    setAddTemplateAnchor(null);
  };

  // useEffect(() => {
  //   // axios.get('http://localhost:8000/api/category')
  //   axios.get('http://localhost:8000/api/viewTemplateListByCategory')

  //     .then(response => {

  //       setCategories(response.data)

  //     })

  //     .catch(error => {

  //       console.log(error);

  //     });

  //   axios.get('http://localhost:8000/api/categoryList')

  //     .then(response => {
  //       setCategoryTemplate(response.data.categories)

  //     })

  //     .catch(error => {

  //       console.log(error);

  //     });
  // }, [])

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     // Call the "users" endpoint
    //     const categoryList = await ApiService.get('categoryList');
    //     setCategoryList(categoryList.data)
    //     console.log('categoryList:', categoryList.data.categories);

    //     // Call the "departments" endpoint
    //     const viewTemplateListByCategory = await ApiService.get('viewTemplateListByCategory');
    //     setViewTemplateListByCategory(viewTemplateListByCategory.data)
    //     console.log('viewTemplateListByCategory:', viewTemplateListByCategory.data);

    //     // Call the "employees" endpoint
    //     // const employees = await ApiService.get('employees');
    //     // console.log('Employees:', employees);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };

    // fetchData();
    ApiService.get('categoryList').then(categoryList => {
      setCategoryList(categoryList.data.categories)
      // console.log(categoryList.data.categories)
    })
    ApiService.get('viewTemplateListByCategory').then(viewTemplateListByCategory => {
      setViewTemplateListByCategory(viewTemplateListByCategory.data)
    })
    ApiService.get('getCanvasUser').then(getCanvasUser => {
      setGetCanvasUser(getCanvasUser.data)
      localStorage.setItem('role', JSON.stringify(getCanvasUser.data.role));
      localStorage.setItem('userId', JSON.stringify(getCanvasUser.data.id));
      // console.log('getCanvasUser:', getCanvasUser.data)
    })
  }, []);

  const handleSubmit = async () => {
    try {
      const templateName = document.getElementById('templateName').value;
      const category = document.getElementById('addCategory').value;
      const tags = selectedTags.map((tag) => ({ name: tag }));
      const descriptionValue = document.getElementById('description').value;
  
      const data = {
        name: templateName,
        category,
        tags: tags,
        description: descriptionValue,
        user:localStorage.getItem('userId')
      };

    
  
      // Make the POST request
      // await ApiService.post('createTemplate', data);
      const response = await ApiService.post('createTemplate', data);
      const id = response.data.id;
      // Reset the form fields if needed
      document.getElementById('templateName').value = '';
      
      // navigate(`/TemplateCreation/${id}`);
      navigate(`/TemplateCreation`);
      // console.log(id)
      localStorage.setItem("templateId",id);
      // Clear other form fields if necessary
  
      // Handle any success or navigation logic
    } catch (error) {
      console.error('Error submitting the form:', error);
      // Handle error scenario
    }
  };

  // useEffect(() => {
  //   console.log({ categories })
  // }, [categories])

  // useEffect(() => {
  //   console.log({ categoryTemplate })
  // }, [categoryTemplate])

  // const addCategory = () => {
  //     setCategories([...categories,{id: categories.length, category: 'Birthday'}])
  // }

  const Img = styled('img')({
    margin: 'auto',
  });

  const renderFunc = (v, i) => {
    return (
      // <Box sx={{border: '1px solid', background: '#fff', textAlign: 'center'}}>
      //         <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      //             <Img alt="complex" src={badge} sx={{ height: '10vh' }} />
      //         </Box>
      //          {v.template}
      // </Box>
      // <Box>
      //   <Card sx={{  }}>

      //     <Box sx={{textAlign:"center"}}>
      //       <Img alt="complex" src={b1} sx={{  }} />
      //     </Box>
      //       <Typography sx={{ fontSize: 14,mt: 1.5 }} color="text.secondary">
      //       {v.template}
      //       </Typography>

      //   </Card>
      // </Box>
     
        <Card sx={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', maxWidth: { xs: '200px', sm: '230px' },'& .iconBTN': {display: 'none'}, '&:hover .iconBTN': {display: 'block'} }}>
        <Box style={{ position: 'relative' }}>
          <IconButton className='iconBTN' aria-label="settings" sx={{position:'absolute',right:'1px',
        
        }}>
            <MoreVertIcon />
       </IconButton>
       </Box>
       <Link to="/templateCreation" onClick={() => {localStorage.setItem('templateId', v.id)}} className='MuiLink-underlineNone'>
          <CardMedia
            sx={{ height: { xs: '170px', sm: '220px' }, m: 1, borderRadius: 1, mb: 0 }}
            image={v.image}
            title={v.name}
          />
          {/* <CardContent> */}
          <Typography gutterBottom variant="subtitle2" component="div" sx={{ textAlign: 'left', mb: 0, p: .5, pl: 1,color:'#000' }}>
            {v.name}
          </Typography>
          {/* <Typography variant="body2" color="text.secondary">
          Lizards are a widespread group of squamate reptiles, with over 6,000
          species, ranging across all continents except Antarctica
        </Typography> */}
          {/* </CardContent> */}
          {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
       </Link>
        </Card>
     
    )
  }

  return (
    <>
      <CssBaseline />
      <AppBar sx={{ background: '#EEF6FF', boxShadow: 'none' }}>
        <Toolbar>

          {/* <Typography
              variant="h7"
              component="div"
              color={'#000'}
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >

              Birthday Black Invitation
            </Typography> */}
          <Box sx={{ flexGrow: 1 }}><img src={thpLogo} /></Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {/* {navItems.map((item) => (
              <Button key={item} sx={{ color: '#fff' }} variant="outlined">
                {item}
              </Button>
            ))} */}

            <IconButton
              sx={{ mr: 1 }}
              size="large"
              aria-label="show 17 new notifications"
              color=""
            >
              <Badge badgeContent={7} color="error">
                <NotificationsActiveIcon />
              </Badge>
            </IconButton>
            <IconButton sx={{ p: 0 }}
              onClick={handleClick}
              size="small"
              aria-controls={openMenu ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
            >
              <Avatar alt="Remy Sharp" sx={{ width: 32, height: 32 }} src={b1} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {/* <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem> */}
              <Typography variant='body1' p={1.5} pl={2}> Welcome Alina, </Typography>
              <Divider />
              <MenuItem onClick={handleClose} sx={{ mt: 1 }}>
                <ListItemIcon>
                  <CollectionsIcon fontSize="small" />
                </ListItemIcon>
                My Templates
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  {/* <Logout fontSize="small" /> */}
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                Profile
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Profile
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ background: '#EEF6FF', position: 'relative', top: 64 }}>

        <Box sx={{ px: 2, pb: 0 }}>
          <Card sx={{ boxShadow: 'none', display: 'flex', alignItems: 'center', p: '8px 20px', background: 'rgba(225, 237, 252, 0.6)' }}>
            <Typography variant='h6'>
              Browse All Tempates
            </Typography>

            <Paper
              component="form"
              sx={{
                display: 'flex', alignItems: 'center', boxShadow: 'none', background: '#FFF',
                borderRadius: '48px', mx: 4, width: '60%'
              }}
            >
              <IconButton type="button" sx={{ p: '10px' }} aria-label="Search Box">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Templates"
                inputProps={{ 'aria-label': 'Search here' }}
              />


            </Paper>

            {userRole === localStorage.getItem('role') &&
              <Box>
                <Button variant="contained" startIcon={<AddIcon />}
                  aria-controls={openAddTemplate ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openAddTemplate ? 'true' : undefined}
                  onClick={clickAddTemp}
                >
                  ADD NEW
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={addTemplateAnchor}
                  open={openAddTemplate}
                  onClose={handleCloseTemp}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {categoryList && categoryList.map((key, index) => {
                    // console.log(categoryList)
                    return (
                      <MenuItem onClick={dialogOpen} key={index}>

                        {/* <Link to="/templateCreation" className='MuiLink-underlineNone'> */}

                          <Typography variant='body2' color='secondary'>{key.category}</Typography>
                        {/* </Link> */}
                      </MenuItem>

                    )
                  })}
                  <MenuItem onClick={dialogOpen}>

                    <Typography variant='body2' color='secondary'>Custom Size</Typography>
                  </MenuItem>
                </Menu>
                  <BootstrapDialog
                    onClose={dialogClose}
                    aria-labelledby="add-category-dialog"
                    open={categoryDialogOpen}
                    aria-describedby="add-category-description"
                    autoFocus
                  >
                    <BootstrapDialogTitle id="add-category-dialog" onClose={dialogClose} variant="subtitle1" sx={{ background: "#DBEAFE" }}>
                      Template Style
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                      {/* <Box sx={{ p: 1, width: "100%", rowGap: "1.5rem", columnGap: "1rem",display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', }}> */}

                      <Grid container spacing={2} p={1}>
                        <Grid item xs={12} md={12}>                          
                          {/* <FormControl fullWidth> */}
                            <TextField id="templateName" label="Template Name" variant="outlined" fullWidth size="large" />
                          {/* </FormControl> */}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          {/* <FormControl fullWidth> */}
                            <Autocomplete
                              value={category}
                              onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                  setCategory({
                                    category: newValue,
                                  });
                                } else if (newValue && newValue.inputValue) {
                                  // Create a new value from the user input
                                  setCategory({
                                    category: newValue.inputValue,
                                  });
                                } else {
                                  setCategory(newValue);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.category);
                                if (inputValue !== '' && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    category: `Add  new Category "${inputValue}"`,
                                  });
                                }

                                return filtered;
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              id="addCategory"
                              options={categoryList}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                // Regular option
                                return option.category;
                              }}
                              renderOption={(props, option) => <li {...props}>{option.category}</li>}
                              freeSolo
                              renderInput={(params) => (
                                <TextField {...params} label="Category" />
                              )}
                            />
                          {/* </FormControl> */}

                        </Grid>
                        <Grid item xs={12} md={12}>

                          {/* <FormControl fullWidth> */}
                            <Autocomplete
                              multiple
                              fullWidth
                              id="addTags"
                              limitTags={2}
                              options={top100Films.map((option) => option.title)}
                              // defaultValue={[top100Films[3].title]}
                              value={selectedTags}
                              onChange={handleTagsChange}
                              freeSolo
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip variant="outlined" label={option} {...getTagProps({ index })} sx={{ borderRadius: '4px', background: 'rgba(0, 0, 0, 0.08)' }} />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}

                                  label="Tags"
                                  placeholder="Add Tags"
                                />
                              )}
                            />
                          {/* </FormControl> */}
                        </Grid>
                        <Grid item xs={12} md={12}>

                          {/* <FormControl fullWidth> */}
                            <TextField
                              id="description"
                              label="Description"
                              multiline
                              fullWidth

                              rows={4}
                            />
                          {/* </FormControl> */}
                        </Grid>

                      </Grid>


                      {/* </Box> */}
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'left' }}>
                      <Button sx={{ m: 2 }} autoFocus variant='contained' onClick={handleSubmit}>
                        SAVE
                      </Button>
                    </DialogActions>
                  </BootstrapDialog>
              </Box>
            }

          </Card>
          <Tags />
        </Box>
        {Object.keys(viewTemplateListByCategory).map((v, i) => {
          return (<Box key={i}>
            {/* {console.log(v,categories[v])} */}
            <Video data={viewTemplateListByCategory[v]} renderFunc={renderFunc} heading={v} style={{ width: "100%" }} />
          </Box>)
        })}
      </Box>
    </>
    // <>
    // {/* </> */}
    // categories.map((v,i) => {
    //     return <Box key={i} >
    //         {v.template}
    //     </Box>
    // })

    //     : <>
    //         <Box sx={{width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    //             <Box sx={{border: '1px solid'}} onClick={addCategory}>
    //                 Start your Journey here.
    //             </Box>
    //         </Box>
    //     </>}
    // </>
  )

}

export default Category;