
const shortCutHelper = ({ insertNode, deleteNode, arrowUp, arrowDown, arrowLeft, arrowRight, undoAction, redoAction}) => {

    let self = {};

    self.listner = (event) => {

        if (event.ctrlKey && event.key === 'z') {
            // Perform Undo operation
            console.log('Undo shortcut triggered');
            undoAction();
            // Add your Undo logic here
        }

        // Check if CTRL key and Y key are pressed (for Redo)
        if (event.ctrlKey && event.key === 'y') {
            // Perform Redo operation
            console.log('Redo shortcut triggered');
            redoAction();
            // Add your Redo logic here
        }

        // Check if Arrow Up key is pressed
        if (event.key === 'ArrowUp') {
            // Perform action for Arrow Up
            console.log('Arrow Up key pressed');
            arrowUp();
            // Add your logic for Arrow Up action here
        }

        // Check if Arrow Down key is pressed
        if (event.key === 'ArrowDown') {
            // Perform action for Arrow Down
            console.log('Arrow Down key pressed');
            arrowDown();
            // Add your logic for Arrow Down action here
        }

        // Check if Arrow Left key is pressed
        if (event.key === 'ArrowLeft') {
            // Perform action for Arrow Left
            console.log('Arrow Left key pressed');
            arrowLeft();
            // Add your logic for Arrow Left action here
        }

        // Check if Arrow Right key is pressed
        if (event.key === 'ArrowRight') {
            // Perform action for Arrow Right
            console.log('Arrow Right key pressed');
            arrowRight();
            // Add your logic for Arrow Right action here
        }

        // Check if Delete key is pressed
        if (event.key === 'Delete') {
            // Perform action for Delete key
            console.log('Delete key pressed');
            deleteNode();
            // Add your logic for Delete action here
        }

        // Check if Insert key is pressed
        if (event.key === 'Insert') {
            // Perform action for Insert key
            console.log('Insert key pressed');
            insertNode();
            // Add your logic for Insert action here
        }

    }

    self.startListning = () => {
        document.addEventListener('keydown', self.listner);
    }

    self.stopListning = () => {
        document.removeEventListener('keydown', self.listner);
    }

    return self;
}

export default shortCutHelper;