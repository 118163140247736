import React from "react";
import { SketchPicker } from 'react-color';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import useOutsideClick from "../../../utils/helper/useOutsideHook";

const SketchPickerField = ({ value, onChange, label, type, component }) => {
    const [colorPickerDisplay, setColorPickerDisplay] = React.useState('none');
    const obj = React.useRef();

    useOutsideClick(obj, () => {
        setColorPickerDisplay('none');
    });

    return (
        <>

            {type != 1 && (
                <Box ref={obj} onClick={(e) => { e.stopPropagation(); setColorPickerDisplay('block') }} sx={{ position: 'relative' }}>
                    {/* <TextField value={value} label={label}></TextField> */}
                    {component}
                    <Box sx={{ display: colorPickerDisplay, position: 'absolute', top: 0, left: 0,  zIndex: '2' }}>
                        <SketchPicker
                            color={value}
                            onChangeComplete={(color) => onChange({ "target": { "value": color.hex } })}
                        />
                    </Box>
                </Box>
            )}
            {type == 1 && (
                <SketchPicker
                    color={value}
                    onChangeComplete={(color) => onChange({ "target": { "value": color.hex } })}
                />
            )}
        </>
    )
}

export default SketchPickerField;