// import CardGenerator from './CardGenerator/CardGenerator';
import Category from './pages/Category/Category';
import AddNewTemplate from './pages/TemplateCreation/AddNewTemplate';
import TemplateCreation from './pages/TemplateCreation/TemplateCreation';
import LayerCreation from './pages/LayerCreation/LayerCreation';
import { Route, Routes } from 'react-router-dom';
// import TemplateCreation from './pages/TemplateCreation/TemplateCreation';
// import LayerCreation from './LayerCreation/LayerCreation';
// import ControllableStates from './Temporary';
import ThemeProvider from './theme';
import './assets/styles/Customized.css';
import './assets/styles/Theme.css';

function App() {
  const userRole = localStorage.getItem('role');
  return (
    <div className="App">
      {/* <CardGenerator/> */}

      {/* <LayerCreation /> */}
      {/* <ControllableStates /> */}
      {/* <TemplateCreation /> */}
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/category" element={(
          <ThemeProvider>
            <Category userRole={userRole} />
          </ThemeProvider>
        )} />
        <Route path="/addNewTemplate" element={(
          <ThemeProvider>
            <AddNewTemplate />
          </ThemeProvider>
        )} />

<Route path="/templateCreation" element={(
          <ThemeProvider>
            <TemplateCreation />
          </ThemeProvider>
        )} />
        {/* <Route path="/dashboard" element={(
          <ThemeProvider>
            <Sidebar window={windowObject}>
              <Dashboard />
            </Sidebar>
            <Footer />
          </ThemeProvider>)} /> */}

<Route path="/layerCreation" element={(
          <ThemeProvider>
            <LayerCreation />
          </ThemeProvider>
        )} />

        <Route path="/" element={(<ThemeProvider>
          <Category userRole={userRole} /></ThemeProvider>)} />
      </Routes>
    </div>
  );
}

export default App;
