// import React from 'react'

// const AddNewTemplate = () => {
//   return (
//     <Box>

//     </Box>
//   )
// }

// export default AddNewTemplate

import * as React from 'react';
// import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import PreviewIcon from '@mui/icons-material/Preview';
import DownloadIcon from '@mui/icons-material/Download';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import b1 from '../../assets/images/b1.png';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CollectionsIcon from '@mui/icons-material/Collections';
import { Link } from "react-router-dom";
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
import TemplateCreation from './TemplateCreation';

// const StyledTab = styled(Tab)({
//   "&.Mui-selected": {
//     color: "rgba(0, 0, 0, 0.85);",
//   },
//   fontWeight: '600', fontSize: '16px', textTransform: 'capitalize'
// });
const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];

function AddNewTemplate(props) {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const stageRef = React.useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Birthday Black Invitation
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar sx={{ background: '#EEF6FF', boxShadow: 'none' }}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none', xs: 'block' } }}
          >
            <MenuIcon />

          </IconButton>
          <Link to='/category' className='MuiLink-underlineNone'>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              sx={{ mr: 2, display: { xs: 'none', sm: 'block' } }}
            >
              <HomeIcon />
            </IconButton>
          </Link>

          <Typography
            variant="h7"
            component="div"
            color={'#000'}
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >

            Birthday Black Invitation
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {/* {navItems.map((item) => (
              <Button key={item} sx={{ color: '#fff' }} variant="outlined">
                {item}
              </Button>
            ))} */}
            <Button variant="outlined" size='medium' startIcon={<PreviewIcon />} sx={{ mr: 1 }}>PREVIEW</Button>
            <Button variant="outlined" size='medium' startIcon={<DownloadIcon />} sx={{ mr: 1 }} >DOWNLOAD</Button>
            <Button variant="contained" size='medium' sx={{ mr: 2 }}>SAVE</Button>
            <IconButton
              sx={{ mr: 1 }}
              size="large"
              aria-label="show 17 new notifications"
              color=""
            >
              <Badge badgeContent={7} color="error">
                <NotificationsActiveIcon />
              </Badge>
            </IconButton>
            <IconButton sx={{ p: 0 }}
              onClick={handleClick}
              size="small"
              aria-controls={openMenu ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
            >
              <Avatar alt="Remy Sharp" sx={{ width: 32, height: 32 }} src={b1} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {/* <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem> */}
              <Typography variant='body1' p={1.5} pl={2}> Welcome Alina, </Typography>
              <Divider />
              <MenuItem onClick={handleClose} sx={{ mt: 1 }}>
                <ListItemIcon>
                  <CollectionsIcon fontSize="small" />
                </ListItemIcon>
                My Templates
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  {/* <Logout fontSize="small" /> */}
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                Profile
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Profile
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 0, width: 1 }}>
        <Toolbar />



        <TemplateCreation />
        {/* <TabContext value={value} > */}

        {/* </TabContext> */}

      </Box>
    </Box>
  );
}



export default AddNewTemplate;