import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Font } from 'google-fonts';
import Autocomplete from '@mui/material/Autocomplete';
// import WebFontLoader from 'webfontloader';
import SketchPickerField from '../component/form/component/SketchPickerField';
import InputSingleSelect from '../component/form/component/InputSingleSelect';
import b1 from '../assets/images/b1.png';
import b2 from '../assets/images/b2.png';
// import FontFaceObserver from 'fontfaceobserver';
import b4 from '../assets/images/b4.png';
import {
  Box, TextField, Typography, Slider, styled, Card, Avatar,
  Divider, ToggleButton, ToggleButtonGroup, Stack, Grid,
  FormatColorFill as FormatColorFillIcon, FormatItalic as FormatItalicIcon, FormatUnderlined as FormatUnderlinedIcon, ArrowDropDown as ArrowDropDownIcon,
  FormatAlignLeft as FormatAlignLeftIcon, FormatAlignCenter as FormatAlignCenterIcon, FormatAlignRight as FormatAlignRightIcon, FormatAlignJustify as FormatAlignJustifyIcon, FormatBold as FormatBoldIcon, UploadFile as UploadFileIcon,
} from '../utils/AllImportsHelper';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const PropertyPanel = ({ value, textChange, handleImageUpload }) => {
  // const [googleFonts, setGoogleFonts] = useState([]);
  const [fontOptions, setFontOptions] = useState([]);
  const [selectedFont, setSelectedFont] = useState("");

  // useEffect(() => {
  //   // Fetch Google Fonts API to retrieve available fonts
  //   const fetchGoogleFonts = async () => {
  //     try {
  //       const response = await axios.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCaKkUfuNSuIiPmzeqYfvMLb5lNrq05juo');
  //       const fonts = response.data.items.map(font => ({
  //         value: font.family,
  //         label: font.family,
  //         fontUrl: `https://fonts.googleapis.com/css2?family=${encodeURIComponent(font.family)}`,
  //         // You can also include additional properties like font weights and styles if needed
  //       }));
  //       setGoogleFonts(fonts);
  //     } catch (error) {
  //       console.error('Error fetching Google Fonts:', error);
  //     }
  //   };

  //   fetchGoogleFonts();
  // }, []);

  useEffect(() => {
    // Fetch the available font options from the Google Fonts API
    fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCaKkUfuNSuIiPmzeqYfvMLb5lNrq05juo')
      .then((response) => response.json())
      .then((data) => {
        const options = data.items.map((font) => ({
          value: font.family,
          label: font.family,
        }));
        setFontOptions(options);
        console.log(options)
      })
      .catch((error) => {
        console.error('Error fetching font options:', error);
      });
  }, []);

  const isOptionEqualToValue = (option, value) => {
    return option && value && option.label === value;
  };

  // const isOptionEqualToValue = (option, value) => {
  //   return option && value && option.value === value.value;
  // };
  // const getOptionLabel = (option) => {
  //   if (!option || !option.label) {
  //     return '';
  //   }

  //   // Handle the special case of "Brush Script MT, Brush Script Std, cursive" font
  //   if (option.label === 'Cedarville Cursive') {
  //     return option.label;
  //   }

  //   // Check if fontFamily is a string, otherwise return an empty string
  //   if (typeof option.label === 'string') {
  //     return option.label.replace(/, /g, ',');
  //   }

  //   return '';
  // };

  // const handleAutocompleteChange = (e, value,v) => {
  //   if (value) {
  //     textChange({ target: { value: value.label } }, v.id, 'fontFamily');
  //   } else {
  //     textChange({ target: { value: '' } }, v.id, 'fontFamily');
  //   }
  // };

  // const loadFont = (fontFamily) => {
  //   const link = document.createElement('link');
  //   link.href = `https://fonts.googleapis.com/css?family=${encodeURIComponent(fontFamily)}`;
  //   link.rel = 'stylesheet';

  //   document.head.appendChild(link);
  // };

  // useEffect(() => {
  //   loadFont(fontFamily);
  // }, []);

  useEffect(() => {
    // // Load the Google Fonts CSS file for the selected font.
    // const cssUrl = `https://fonts.googleapis.com/css2?family=${fontOptions}&display=swap`;



    // const link = document.createElement("link");
    // link.href = cssUrl;
    // link.rel = "stylesheet";



    // document.head.appendChild(link);
  }, [fontOptions]);

  return (
    <>
      {value.map(v => {
        switch (v.type) {
          case 'textNode': return (
            <Box key={v.id} component="form" sx={{ p: 2, pt: 3, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: "100%", rowGap: "1.5rem", columnGap: "1rem" }}>
              <TextField value={v.value} onChange={(e) => textChange(e, v.id, 'value')} variant="outlined" label="Text" sx={{ gridColumnStart: 1, gridColumnEnd: 3 }} />
              {/* <InputSingleSelect label="Choose Font" value={v.fontFamily} change={(e) => textChange(e, v.id, 'fontFamily')}  data={googleFonts}  variant="outlined" /> */}

              <Autocomplete
                value={v.fontFamily}
                // value={fontOptions.find(option => option.label === v?.fontFamily) || null}
                onChange={(e, value) => { console.log({ e, value }); textChange({ target: { value: value?.label ?? value } }, v.id, 'fontFamily', 'fontSelected'); }}
                // onChange={handleAutocompleteChange}
                options={fontOptions}
                onInputChange={(e, value, options) => console.log({ value, options })}
                isOptionEqualToValue={isOptionEqualToValue}
                // getOptionLabel={getOptionLabel}
                // getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined"
                    label="Choose Font"
                  // InputProps={{
                  //   ...params.InputProps,
                  //   style: { fontFamily: [...v.fontFamily, 'sans-serif'].join(', ') }
                  // }}
                  />
                )}
              />
              {/* <Autocomplete
              autoComplete
      id="grouped-demo"
      options={googleFonts.map( v => v.name)}
      freeSolo
      disableClearable
      getOptionLabel={(option) => option.label}
      value={v.fontFamily}
      onChange={(e, newValue) => textChange({ target: { value: newValue } }, v.id, 'fontFamily')}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search input"
          InputProps={{
            ...params.InputProps,
            type: 'search',
          }}/>
          )} 
    /> */}
              <TextField value={v.fontSize} onChange={(e) => textChange(e, v.id, 'fontSize')} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" label="Font Size" />
              <TextField value={v.opacity} onChange={(e) => textChange(e, v.id, 'opacity')} variant="outlined" label="Opacity" />
              <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '80%' }}>
                  <Typography sx={{ gridColumnStart: 1, gridColumnEnd: 1 }} variant="caption" fontWeight={550}>Rotate</Typography>
                  <Slider
                    size="small"
                    sx={{ ml: .8 }}
                    max={360}
                    defaultValue={0}
                    value={v.rotation}
                    valueLabelDisplay="auto"
                    onChange={(e) => textChange(e, v.id, 'rotation')} />
                </Box>
                <Box sx={{ width: '15%', display: 'flex', alignItems: 'center' }}>

                  <TextField size="small" value={v.rotation} onChange={(e) => textChange(e, v.id, 'rotation')} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" />
                </Box>
              </Box>
              <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                <Typography variant="caption" fontWeight={550}>Alignment</Typography>
                <Card
                  elevation={0}
                  sx={{
                    display: 'flex',
                    mt: 1,
                    boxShadow: 'none',
                    //  border: (theme) => `1px solid ${theme.palette.divider}`,
                    flexWrap: 'wrap',
                  }}>
                  <StyledToggleButtonGroup
                    value={v.align}
                    exclusive
                    size="small"
                    onChange={(e, alignment = '') => textChange({ target: { value: alignment } }, v.id, 'align')}
                    aria-label="text alignment">
                    <ToggleButton value="left" aria-label="left aligned">
                      <FormatAlignLeftIcon fontSize='small' />
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="centered">
                      <FormatAlignCenterIcon fontSize='small' />
                    </ToggleButton>
                    <ToggleButton value="right" aria-label="right aligned">
                      <FormatAlignRightIcon fontSize='small' />
                    </ToggleButton>
                    <ToggleButton value="justify" aria-label="justified" disabled>
                      <FormatAlignJustifyIcon fontSize='small' />
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                  <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                  <StyledToggleButtonGroup
                    size="small"
                    value={v.fontStyle1}
                    onChange={(e, font = '') => { textChange({ target: { value: font } }, v.id, 'fontStyle1') }}
                    aria-label="text formatting">
                    <ToggleButton value="bold1" aria-label="bold">
                      <FormatBoldIcon fontSize='small' />
                    </ToggleButton>
                    <ToggleButton value="italic1" aria-label="italic">
                      <FormatItalicIcon fontSize='small' />
                    </ToggleButton>
                    <ToggleButton value="underline1" aria-label="underline" onClick={(e) => textChange({ target: { value: 'underlined1' } }, v.id, 'textDecoration1')}>
                      <FormatUnderlinedIcon fontSize='small' />
                    </ToggleButton>
                    <ToggleButton value="showPicker" aria-label="showPicker" onClick={(e) => textChange({ target: { value: 'showPicker' } }, v.id, 'showPicker')}>
                      <FormatColorFillIcon fontSize='small' />
                      <ArrowDropDownIcon fontSize='small' />
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                  <Box sx={{ m: v.showPicker ? 2 : 0 }}>
                    {v.showPicker != '' &&
                      (<SketchPickerField value={v.fill} label={'Color'} onChange={(e) => textChange(e, v.id, 'fill')} type={1} />)
                    }
                  </Box>
                </Card>
              </Box>
            </Box>);
          // case 'headingTextNode': return (
          //   <Box key={v.id} component="form" sx={{ p: 2, pt: 3, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: "100%", rowGap: "1.5rem", columnGap: "1rem" }}>
          //     <TextField value={v.value} onChange={(e) => textChange(e, v.id, 'value')} variant="outlined" label="Text" sx={{ gridColumnStart: 1, gridColumnEnd: 3 }} />
          //     <InputSingleSelect label="Choose Font" value={v.fontFamily} change={(e) => textChange(e, v.id, 'fontFamily')} data={[{ value: 'Arial', label: 'Arial' },{value:'Brush Script MT, Brush Script Std, cursive', label:'Cursive'}, { value: 'Helvetica', label: 'Helvetica' }, { value: 'Times New Roman', label: 'Times New Roman' }, { value: 'Calibri', label: 'Calibri' }, { value: 'Georgia', label: 'Georgia' }]} variant="outlined" />
          //     <TextField value={v.fontSize} onChange={(e) => textChange(e, v.id, 'fontSize')} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" label="Font Size" />
          //     <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex', justifyContent: 'space-between' }}>
          //       <Box sx={{ width: '80%' }}>
          //         <Typography sx={{ gridColumnStart: 1, gridColumnEnd: 1 }} variant="caption" fontWeight={550}>Rotate</Typography>
          //         <Slider
          //           size="small"
          //           sx={{ ml: .8 }}
          //           max={360}
          //           defaultValue={0}
          //           value={v.rotation}
          //           valueLabelDisplay="auto"
          //           onChange={(e) => textChange(e, v.id, 'rotation')} />
          //       </Box>
          //       <Box sx={{ width: '15%', display: 'flex', alignItems: 'center' }}>

          //         <TextField size="small" value={v.rotation} onChange={(e) => textChange(e, v.id, 'rotation')} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" />
          //       </Box>
          //     </Box>
          //     <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          //       <Typography variant="caption" fontWeight={550}>Alignment</Typography>
          //       <Card
          //         elevation={0}
          //         sx={{
          //           display: 'flex',
          //           mt: 1,
          //           boxShadow: 'none',
          //           //  border: (theme) => `1px solid ${theme.palette.divider}`,
          //           flexWrap: 'wrap',
          //         }}>
          //         <StyledToggleButtonGroup
          //           value={v.align}
          //           exclusive
          //           size="small"
          //           onChange={(e, alignment = '') => textChange({ target: { value: alignment } }, v.id, 'align')}
          //           aria-label="text alignment">
          //           <ToggleButton value="left" aria-label="left aligned">
          //             <FormatAlignLeftIcon fontSize='small' />
          //           </ToggleButton>
          //           <ToggleButton value="center" aria-label="centered">
          //             <FormatAlignCenterIcon fontSize='small' />
          //           </ToggleButton>
          //           <ToggleButton value="right" aria-label="right aligned">
          //             <FormatAlignRightIcon fontSize='small' />
          //           </ToggleButton>
          //           <ToggleButton value="justify" aria-label="justified" disabled>
          //             <FormatAlignJustifyIcon fontSize='small' />
          //           </ToggleButton>
          //         </StyledToggleButtonGroup>
          //         <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          //         <StyledToggleButtonGroup
          //           size="small"
          //           value={v.fontStyle1}
          //           onChange={(e, font = '') => { textChange({ target: { value: font } }, v.id, 'fontStyle1') }}
          //           aria-label="text formatting">
          //           <ToggleButton value="bold1" aria-label="bold">
          //             <FormatBoldIcon fontSize='small' />
          //           </ToggleButton>
          //           <ToggleButton value="italic1" aria-label="italic">
          //             <FormatItalicIcon fontSize='small' />
          //           </ToggleButton>
          //           <ToggleButton value="underline1" aria-label="underline" onClick={(e) => textChange({ target: { value: 'underlined1' } }, v.id, 'textDecoration1')}>
          //             <FormatUnderlinedIcon fontSize='small' />
          //           </ToggleButton>
          //           <ToggleButton value="showPicker" aria-label="showPicker" onClick={(e) => textChange({ target: { value: 'showPicker' } }, v.id, 'showPicker')}>
          //             <FormatColorFillIcon fontSize='small' />
          //             <ArrowDropDownIcon fontSize='small' />
          //           </ToggleButton>
          //         </StyledToggleButtonGroup>
          //         <Box sx={{ m: v.showPicker ? 2 : 0 }}>
          //           {v.showPicker != '' &&
          //             (<SketchPickerField value={v.fill} label={'Color'} onChange={(e) => textChange(e, v.id, 'fill')} type={1} />)
          //           }
          //         </Box>
          //       </Card>
          //     </Box>
          //   </Box>);
          case 'imageNode': return (<Box key={v.id} component="form" sx={{ p: 2, pt: 3, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: "100%", rowGap: "1.5rem", columnGap: "1rem" }}>
            <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: "0.5rem",
                  border: "1px dashed rgba(0, 0, 0, 0.12)",
                  borderRadius: "4px",
                  backgroundColor: "#ffffff",
                  ":hover": { backgroundColor: "#DBEAFE", borderColor: "var(--color-mui-primary-blue)" },
                }}>
                <Box className="upload-file-icon" sx={{ p: "1rem 0rem" }}>
                  <Avatar sx={{ height: '2.5rem', width: '2.5rem', backgroundColor: '#1976D21F' }}>
                    <UploadFileIcon color="primary" />
                  </Avatar>
                </Box>
                <Box className="upload-btn-wrapper" >
                  <span>
                    <Link className="btn" sx={{ textDecoration: "underline" }}>Click to upload</Link>

                    <input
                      name="upload-photo" type="file" onChange={(e) => handleImageUpload(e, v.id)}
                      multiple
                      accept="image/*,.mp4,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                  </span>
                  <span> or drag and drop </span>
                </Box>

                <Box m='1rem 0rem'>
                  <Typography variant="body2" color="text.secondary" display="block" gutterBottom>SVG, PNG, JPG or GIF (max. 3MB)</Typography>
                </Box>
              </Stack>
            </Box>
            <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
              <Typography sx={{ gridColumnStart: 1, gridColumnEnd: 1 }} variant="caption" fontWeight={550}>Recently Uploaded</Typography>
              <Grid container spacing={2} columns={12} sx={{ pt: 1 }}>
                <Grid item sm={4}>
                  <Card sx={{ p: 1.5 }} ><Card sx={{ height: 80 }}><img src={b1} height={80} width='100%' /></Card></Card>
                </Grid>
                <Grid item sm={4}>
                  <Card sx={{ p: 1.5 }} ><Card sx={{ height: 80 }}><img src={b2} height={80} width='100%' /></Card></Card>
                </Grid>
                <Grid item sm={4}>
                  <Card sx={{ p: 1.5 }} ><Card sx={{ height: 80 }}><img src={b4} height={80} width='100%' /></Card></Card>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ width: '80%' }}>
                <Typography sx={{ gridColumnStart: 1, gridColumnEnd: 1 }} variant="caption" fontWeight={550}>Rotate</Typography>
                <Slider
                  size="small"
                  sx={{ ml: .8 }}
                  defaultValue={0}
                  value={v.rotation}
                  max={360}
                  valueLabelDisplay="auto"
                  onChange={(e) => textChange(e, v.id, 'rotation')} />
              </Box>
              <Box sx={{ width: '15%', display: 'flex', alignItems: 'center' }}>
                <TextField size="small" value={v.rotation} onChange={(e) => textChange(e, v.id, 'rotation')} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" />
              </Box>
            </Box>
          </Box>
          );
          case 'circleNode': return (
            <Box key={v.id} component="form" sx={{ p: 2, pt: 3, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: "100%", rowGap: "1.5rem", columnGap: "1rem" }}>
              {/* <SketchPickerField value={v.fill} label={'Color'} onChange={(e) => textChange(e, v.id, 'fill')} /> */}
              <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex' }}>
                <SketchPickerField value={v.fill} label={'Color'} onChange={(e) => textChange(e, v.id, 'fill')} component={<Box sx={{ gridColumnStart: 1, gridColumnEnd: 1, width: 35, height: 35, cursor: 'pointer', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.6)', border: '2px solid transparent', borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)', borderImageSlice: 1 }} />} />
                <SketchPickerField value={v.stroke} label={'Color'} onChange={(e) => textChange(e, v.id, 'stroke')} component={<Box sx={{ mx: 3, width: 35, height: 35, cursor: 'pointer', borderRadius: '4px', backgroundColor: '#FBFDFF', border: '2px solid transparent', borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)', borderImageSlice: 1 }} />} />
              </Box>
              <TextField sx={{ gridColumnStart: 1, gridColumnEnd: 3 }} value={v.radius} onChange={(e) => textChange(e, v.id, 'radius')} variant="outlined" label="Radius" />
              <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '80%' }}>

                  <Typography sx={{ gridColumnStart: 1, gridColumnEnd: 1 }} variant="caption" fontWeight={550}>Rotate</Typography>
                  <Slider
                    size="small"
                    sx={{ ml: .8 }}
                    defaultValue={0}
                    aria-label="Small"
                    max={360}
                    value={v.rotation}
                    valueLabelDisplay="auto"
                    onChange={(e) => textChange(e, v.id, 'rotation')} />
                </Box>
                <Box sx={{ width: '15%', display: 'flex', alignItems: 'center' }}>
                  <TextField size="small" value={v.rotation} onChange={(e) => textChange(e, v.id, 'rotation')} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" />
                </Box>
              </Box>
            </Box>
          );
          case 'rectangleNode': return (
            <Box key={v.id} component="form" sx={{ p: 2, pt: 3, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: "100%", rowGap: "1.5rem", columnGap: "1rem" }}>
              <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex' }}>
                <SketchPickerField value={v.fill} label={'Color'} onChange={(e) => textChange(e, v.id, 'fill')} component={<Box sx={{ gridColumnStart: 1, gridColumnEnd: 1, width: 35, height: 35, cursor: 'pointer', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.6)', border: '2px solid transparent', borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)', borderImageSlice: 1 }} />} />
                <SketchPickerField value={v.stroke} label={'Color'} onChange={(e) => textChange(e, v.id, 'stroke')} component={<Box sx={{ mx: 3, width: 35, height: 35, cursor: 'pointer', borderRadius: '4px', backgroundColor: '#FBFDFF', border: '2px solid transparent', borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)', borderImageSlice: 1 }} />} />
              </Box>
              <TextField value={v.strokeWidth} onChange={(e) => textChange(e, v.id, 'strokeWidth')} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" label="Stroke Width" />
              <TextField value={v.shadowBlur} onChange={(e) => textChange(e, v.id, 'shadowBlur')} variant="outlined" label="Shadow Blur" />
              <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '80%' }}>
                  <Typography sx={{ gridColumnStart: 1, gridColumnEnd: 1 }} variant="caption" fontWeight={550}>Rotate</Typography>
                  <Slider
                    size="small"
                    sx={{ ml: .8 }}
                    defaultValue={0}
                    aria-label="Small"
                    max={360}
                    value={v.rotation}
                    valueLabelDisplay="auto"
                    onChange={(e) => textChange(e, v.id, 'rotation')} />
                </Box>
                <Box sx={{ width: '15%', display: 'flex', alignItems: 'center' }}>
                  <TextField size="small" value={v.rotation} onChange={(e) => textChange(e, v.id, 'rotation')} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" />
                </Box>
              </Box>
            </Box>
          );
          case 'starNode': return (
            <Box key={v.id} component="form" sx={{ p: 2, pt: 3, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: "100%", rowGap: "1.5rem", columnGap: "1rem" }}>
              <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex' }}>
                <SketchPickerField value={v.fill} label={'Color'} onChange={(e) => textChange(e, v.id, 'fill')} component={<Box sx={{ gridColumnStart: 1, gridColumnEnd: 1, width: 35, height: 35, cursor: 'pointer', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.6)', border: '2px solid transparent', borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)', borderImageSlice: 1 }} />} />
                <SketchPickerField value={v.stroke} label={'Color'} onChange={(e) => textChange(e, v.id, 'stroke')} component={<Box sx={{ mx: 3, width: 35, height: 35, cursor: 'pointer', borderRadius: '4px', backgroundColor: '#FBFDFF', border: '2px solid transparent', borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)', borderImageSlice: 1 }} />} />
              </Box>
              <TextField value={v.innerRadius} onChange={(e) => textChange(e, v.id, 'innerRadius')} variant="outlined" label="Inner Radius" />
              <TextField value={v.outerRadius} onChange={(e) => textChange(e, v.id, 'outerRadius')} variant="outlined" label="Outer Radius" />
              <TextField value={v.strokeWidth} onChange={(e) => textChange(e, v.id, 'strokeWidth')} variant="outlined" label="Stroke Width" />
              <TextField value={v.shadowOffsetX} onChange={(e) => textChange(e, v.id, 'shadowOffsetX')} variant="outlined" label="Shadow Offset X" />
              <TextField value={v.shadowOffsetY} onChange={(e) => textChange(e, v.id, 'shadowOffsetY')} variant="outlined" label="shadow Offset Y" />
              <TextField value={v.shadowBlur} onChange={(e) => textChange(e, v.id, 'shadowBlur')} variant="outlined" label="Shadow Blur" />
              <TextField value={v.numPoints} onChange={(e) => textChange(e, v.id, 'numPoints')} variant="outlined" label="Num Points" />
              <TextField value={v.shadowOpacity} onChange={(e) => textChange(e, v.id, 'shadowOpacity')} variant="outlined" label="Shadow Opacity" />
              <TextField value={v.opacity} onChange={(e) => textChange(e, v.id, 'opacity')} variant="outlined" label="Opacity" />
              <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '80%' }}>
                  <Typography sx={{ gridColumnStart: 1, gridColumnEnd: 1 }} variant="caption" fontWeight={550}>Rotate</Typography>
                  <Slider
                    size="small"
                    sx={{ ml: .8 }}
                    defaultValue={0}
                    max={360}
                    value={v.rotation}
                    valueLabelDisplay="auto"
                    onChange={(e) => textChange(e, v.id, 'rotation')} />
                </Box>
                <Box sx={{ width: '15%', display: 'flex', alignItems: 'center' }}>
                  <TextField size="small" value={v.rotation} onChange={(e) => textChange(e, v.id, 'rotation')} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" />
                </Box>
              </Box>
            </Box>
          );

          case 'ellipseNode': return (
            <Box key={v.id} component="form" sx={{ p: 2, pt: 3, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: "100%", rowGap: "1.5rem", columnGap: "1rem" }}>
              <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex' }}>
                <SketchPickerField value={v.fill} label={'Color'} onChange={(e) => textChange(e, v.id, 'fill')} component={<Box sx={{ gridColumnStart: 1, gridColumnEnd: 1, width: 35, height: 35, cursor: 'pointer', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.6)', border: '2px solid transparent', borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)', borderImageSlice: 1 }} />} />
                <SketchPickerField value={v.stroke} label={'Color'} onChange={(e) => textChange(e, v.id, 'stroke')} component={<Box sx={{ mx: 3, width: 35, height: 35, cursor: 'pointer', borderRadius: '4px', backgroundColor: '#FBFDFF', border: '2px solid transparent', borderImage: 'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)', borderImageSlice: 1 }} />} />
              </Box>
              {/* <TextField value={v.innerRadius} onChange={(e) => textChange(e, v.id, 'innerRadius')} variant="outlined" label="Inner Radius" />
              <TextField value={v.outerRadius} onChange={(e) => textChange(e, v.id, 'outerRadius')} variant="outlined" label="Outer Radius" /> */}
              <TextField value={v.strokeWidth} onChange={(e) => textChange(e, v.id, 'strokeWidth')} variant="outlined" label="Stroke Width" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
              <TextField value={v.shadowOffsetX} onChange={(e) => textChange(e, v.id, 'shadowOffsetX')} variant="outlined" label="Shadow Offset X" />
              <TextField value={v.shadowOffsetY} onChange={(e) => textChange(e, v.id, 'shadowOffsetY')} variant="outlined" label="shadow Offset Y" />
              <TextField value={v.shadowBlur} onChange={(e) => textChange(e, v.id, 'shadowBlur')} variant="outlined" label="Shadow Blur" />
              {/* <TextField value={v.numPoints} onChange={(e) => textChange(e, v.id, 'numPoints')} variant="outlined" label="Num Points" /> */}
              <TextField value={v.shadowOpacity} onChange={(e) => textChange(e, v.id, 'shadowOpacity')} variant="outlined" label="Shadow Opacity" />
              <TextField value={v.opacity} onChange={(e) => textChange(e, v.id, 'opacity')} variant="outlined" label="Opacity" />
              <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '80%' }}>
                  <Typography sx={{ gridColumnStart: 1, gridColumnEnd: 1 }} variant="caption" fontWeight={550}>Rotate</Typography>
                  <Slider
                    size="small"
                    sx={{ ml: .8 }}
                    defaultValue={0}
                    max={360}
                    value={v.rotation}
                    valueLabelDisplay="auto"
                    onChange={(e) => textChange(e, v.id, 'rotation')} />
                </Box>
                <Box sx={{ width: '15%', display: 'flex', alignItems: 'center' }}>
                  <TextField size="small" value={v.rotation} onChange={(e) => textChange(e, v.id, 'rotation')} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} variant="outlined" />
                </Box>
              </Box>
            </Box>
          );
        }
      })}
    </>
  )
}

export default PropertyPanel