import React from 'react';
import { Image, Circle, Group, Shape } from 'react-konva';

const PenTool = ({ node, tool, height, width, updateParent, currentNode, currentTool }) => {
    const [quads, setQuads] = React.useState([]);
    const [points, setPoints] = React.useState(node?.points ?? []);
    const [controlPoints, setControlPoints] = React.useState(node?.controlPoints ?? []);

    const updatePoint = (update) => {
        setPoints([...update]);
    }

    const updateControlPoint = (update) => {
        setControlPoints([...update]);
    }

    const calcQuads = () => {
        if (points.length == 0) {
            setQuads([]);
        } else if (points.length == 1) {
            setQuads([{ start: points[0] }]);
        } else {
            setQuads([...controlPoints.map((v, i) => {
                return {
                    start: points[i],
                    control: controlPoints[i],
                    end: points[i + 1]
                }
            })])
        }
    }

    React.useEffect(() => {
        calcQuads();
    }, [])

    React.useEffect(() => {
        if (currentTool == 'PEN' && node.id == currentNode){
            calcQuads();
            updateParent({ ...node, points, controlPoints }, node.id);
        }
    }, [points, controlPoints]);

    const handleClick = (e, c) => {
        if (currentTool == 'PEN') {
            let { x, y } = document.getElementById("container").getBoundingClientRect();
            let xPoint = e.evt.x - x;
            let yPoint = e.evt.y - y;
            let updatedControlPoints = points.length < 1 ? [] : points.map((v, i) => {
                return controlPoints[i] ?? { x: (points[i].x + xPoint) / 2, y: (points[i].y + yPoint) / 2 }
            });
            updatePoint([...points, { x: xPoint, y: yPoint }]);
            updateControlPoint([...updatedControlPoints])
        }
    }

    const dragingPoint = (e, c, i) => {
        if (tool == 'PEN') {
            let newQuad = [...points];
            if (c == 'start') {
                newQuad[i] = { x: e.target.attrs.x, y: e.target.attrs.y };
                updatePoint(newQuad);
            } else if (c == 'end') {
                newQuad[i + 1] = { x: e.target.attrs.x, y: e.target.attrs.y }
                updatePoint(newQuad);
            } else {
                newQuad = [...controlPoints];
                newQuad[i] = { x: e.target.attrs.x, y: e.target.attrs.y };
                updateControlPoint(newQuad);
            }
        }
    }


    return (
        <Group x={0}
            y={0}
            width={width}
            height={height}>
            <Image
                x={0}
                y={0}
                width={width}
                height={height}
                onClick={handleClick} />
            {quads.length != 0 && (<>
                <Shape
                    stroke='red'
                    strokeWidth={4}
                    fill='blue'
                    sceneFunc={(ctx, shape) => {
                        ctx.beginPath();
                        ctx.moveTo(quads[0].start.x, quads[0].start.y);
                        quads.map((quad, i) => {
                            if (quad.control?.x) {
                                ctx.quadraticCurveTo(
                                    quad.control.x,
                                    quad.control.y,
                                    quad.end.x,
                                    quad.end.y
                                );
                            }
                        })
                        ctx.closePath();
                        ctx.fillStrokeShape(shape);
                    }}
                />
            </>)}

            {node.id == currentNode && quads.map((quad, i) => {
                return (
                    <Group
                        key={quad.id ?? i}>
                        <Circle
                            x={quad.start.x}
                            y={quad.start.y}
                            radius={5}
                            stroke={'#666'}
                            fill={'#ddd'}
                            strokeWidth={2}

                            draggable
                            onDragmove={(e) => dragingPoint(e, 'start', i)}
                        />
                        {quad.control?.x && (<>
                            <Circle
                                x={quad.control.x}
                                y={quad.control.y}
                                radius={5}
                                stroke={'#666'}
                                fill={'#333'}
                                strokeWidth={2}

                                draggable
                                onDragmove={(e) => dragingPoint(e, 'control', i)}
                            />
                            <Circle
                                x={quad.end.x}
                                y={quad.end.y}
                                radius={5}
                                stroke={'#666'}
                                fill={'#ddd'}
                                strokeWidth={2}

                                draggable
                                onDragmove={(e) => dragingPoint(e, 'end', i)}
                            />
                        </>)}
                    </Group>
                );
            })}

        </Group>
    )

}

export default PenTool;